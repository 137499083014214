import React, { useState, useRef, useEffect } from 'react';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';

const MusicInput = ({ link, color, cover }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showCover, setShowCover] = useState(cover === true);
  const audioRef = useRef(new Audio(link));

  useEffect(() => {
    audioRef.current.loop = true
  }, [link]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const handleCoverClick = () => {
    setShowCover(false); 
    audioRef.current.play();
    setIsPlaying(true); 
  };

  const bgColor = color ? color : "#d9d9d970";

  return (
    <div className="relative">
      {showCover ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm" style={{ zIndex: 999 }}>
          <button
            onClick={handleCoverClick}
            className="text-white text-3xl p-4 border border-white rounded"
            style={{ background: 'transparent', border: 'none' }}
          >
            Click
          </button>
        </div>
      ) : (
        <div className="fixed flex flex-col items-center p-2 rounded-xl mt-5" style={{
          background: bgColor,
          width: "45px",
          left: "20px",
          top: "20px",
          cursor: "pointer",
          zIndex: 1000, 
        }}>
          <div className="volume-container flex flex-col items-center">
            <button onClick={togglePlayPause} className="text-white mb-1" style={{ fontSize: "25px" }}>
              {isPlaying ? <FaVolumeUp /> : <FaVolumeMute />}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MusicInput;

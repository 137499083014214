import React from 'react';

function removeDiscordEmojis(text) {
  return text ? text.replace(/<:[\w_]+:\d+>|<a:[\w_]+:\d+>/g, '') : '';
}

function removeFormatting(text) {
  return text ? text.replace(/[*~]/g, '') : '';
}

function renderTimestamps(text) {
  const timestampRegex = /<t:(\d+):([RrFfDdTt])?>/g;
  return text.replace(timestampRegex, (match, timestamp, format) => {
    const date = new Date(timestamp * 1000);
    let displayText;

    switch (format) {
      case 'R': // Relative time
        displayText = '1 month ago'; // Simular formato relativo (use uma biblioteca para precisão)
        break;
      case 'T': // Short time
        displayText = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        break;
      case 't': // Long time
        displayText = date.toLocaleTimeString();
        break;
      case 'D': // Short date
        displayText = date.toLocaleDateString();
        break;
      case 'd': // Long date
        displayText = date.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' });
        break;
      case 'F': // Long date with short time
        displayText = `${date.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' })} at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        break;
      default: // Long date with day of the week
        displayText = date.toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }) + ` at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    }

    return `<span class="timestamp" data-tooltip-id="my-tooltip" data-tooltip-content="${date.toUTCString()}" data-tooltip-offset="5">${displayText}</span>`;
  });
}

const UserBio = ({ bio }) => {
  const renderBio = (bio) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let cleanedBio = renderTimestamps(removeFormatting(removeDiscordEmojis(bio)));
    const parts = cleanedBio.split('\n');

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part.split(urlRegex).map((subPart, subIndex) => {
          if (urlRegex.test(subPart)) {
            return (
              <a
                key={`link-${subIndex}`}
                href={subPart}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#6495ED' }}
              >
                {subPart}
              </a>
            );
          }
          return (
            <span
              key={`text-${subIndex}`}
              dangerouslySetInnerHTML={{ __html: subPart }}
            />
          );
        })}
        {index !== parts.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <p
      className="text-white"
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {renderBio(bio || 'No bio available')}
    </p>
  );
};

export default UserBio;

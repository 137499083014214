import React, { useState, useEffect } from 'react';
import { ArrowRight, User, Settings, Copy, LogOut } from 'lucide-react';
import { useAuth } from '../hooks/useAuth'; 

const membros = [
  { nome: 'Brunno', funcao: 'Owner', imagem: 'https://api.victims.lol/api/avatar/73598582153805824', idade: 17, instagram: "https://instagram.com/imsbrunno" },
  { nome: 'Biel', funcao: 'Owner', imagem: 'https://api.victims.lol/api/avatar/347796715363106816', idade: 17, instagram: "https://instagram.com/gabmkzz" },
  { nome: 'Infinity', funcao: 'Owner', imagem: 'https://api.victims.lol/api/avatar/145922846873485312', idade: 16, instagram: "https://instagram.com/juvena_jov" },
  { nome: 'Lift', funcao: 'Owner', imagem: 'https://api.victims.lol/api/avatar/653584324595023883', idade: 18, instagram: "https://instagram.com/liftzera" },
  { nome: 'Kaike', funcao: 'Owner', imagem: 'https://api.victims.lol/api/avatar/225482315881512960', idade: 19, instagram: "https://instagram.com/ruinbody" },
  { nome: 'Hymaro', funcao: 'Developer', imagem: 'https://api.victims.lol/api/avatar/1297849599570219029', idade: 16, instagram: "https://instagram.com/hymaro.lol" },
  { nome: 'Akz', funcao: 'Administrador(a)', imagem: 'https://api.victims.lol/api/avatar/533272721434345490', idade: 17, instagram: "https://instagram.com/henrixlz_" },
  { nome: 'FP', funcao: 'Administrador(a)', imagem: 'https://api.victims.lol/api/avatar/924340860324294696', idade: 20, instagram: "https://instagram.com/victims.lol" },
  { nome: 'Yuri', funcao: 'Administrador(a)', imagem: 'https://api.victims.lol/api/avatar/1196632416400453632', idade: 17, instagram: "https://instagram.com/vwsyuri" },
  { nome: 'Hande', funcao: 'Support', imagem: 'https://api.victims.lol/api/avatar/1253448708805365870', idade: 15, instagram: "https://instagram.com/_xisluv" },
];

function SobreNos() {
  return (
    <section className="text-white py-16 text-center">
      <h2 className="text-3xl font-semibold mb-8">Sobre nós</h2>
      <p className="max-w-lg mx-auto mb-12 text-lg">
        Nós somos uma <span className="font-bold">Equipe</span> de programadores, focada em promover momentos de lazer aos usuários que nos apoiam.
      </p>
      
      <div className="flex flex-wrap justify-center gap-8 max-w-screen-lg mx-auto">
        <div className="flex justify-center flex-wrap gap-4 w-full mb-8">
          {membros.slice(0, 6).map((membro, index) => (
            <div key={index} className="w-[150px] text-center relative group">
              <img
                src={membro.imagem}
                alt={membro.nome}
                className="w-32 h-32 rounded-lg mb-4 object-cover mx-auto group-hover:opacity-40 transition-opacity duration-200"
              />
              <a
                href={membro.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <img
                  src="https://img.icons8.com/ios-filled/50/ffffff/instagram-new.png" 
                  alt="Instagram"
                  className="w-10 h-10 mb-[57px]"
                />
              </a>
              <p
                className={`text-sm light-effect ${membro.funcao === 'Owner' ? 'text-white-500' : 'text-white-600'} mb-1 relative`}
              >
                {membro.funcao}
              </p>
              <h3 className="text-lg font-semibold">
                {membro.nome}
                <span className="text-gray-400"> - {membro.idade}y</span>
              </h3>
            </div>
          ))}
        </div>

        <div className="flex justify-center flex-wrap gap-4 w-full">
          {membros.slice(6, 11).map((membro, index) => (
            <div key={index} className="w-[150px] text-center relative group">
              <img
                src={membro.imagem}
                alt={membro.nome}
                className="w-32 h-32 rounded-lg mb-4 object-cover mx-auto group-hover:opacity-40 transition-opacity duration-200"
              />
              <a
                href={membro.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <img
                  src="https://img.icons8.com/ios-filled/50/ffffff/instagram-new.png"
                  alt="Instagram"
                  className="w-10 h-10 mb-[57px]"
                />
              </a>
              <p
                className={`text-sm light-effect ${membro.funcao === 'Owner' ? 'text-white-500' : 'text-white-600'} mb-1 relative`}
              >
                {membro.funcao}
              </p>
              <h3 className="text-lg font-semibold">
                {membro.nome}
                <span className="text-gray-400"> - {membro.idade}y</span>
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

<style jsx>{`
  .light-effect {
    position: relative;
    color: #ffffff;
  }
  .light-effect::before,
  .light-effect::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: #ffffff;
    border-radius: 50%;
    animation: sparkle 1.5s infinite ease-in-out alternate;
    opacity: 0.8;
  }
  .light-effect::before {
    top: -6px;
    left: -6px;
    animation-delay: 0.3s;
  }
  .light-effect::after {
    bottom: -6px;
    right: -6px;
    animation-delay: 0.6s;
  }
  
  @keyframes sparkle {
    0% { transform: scale(1); opacity: 0.5; }
    100% { transform: scale(1.4); opacity: 1; }
  }
`}</style>



const HomePage = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
        document.title = "victims.lol | home";
    
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png';
        link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';
    
        const existingLink = document.querySelector("link[rel*='icon']");
        if (existingLink) {
          document.head.removeChild(existingLink);
        }
    
        document.head.appendChild(link);
        
        return () => {
          document.head.removeChild(link);
        };
      }, []);

    const handleDropdownToggle = () => {
        setDropdownOpen(prevState => !prevState);
    };

    const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";
    
    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar 
          ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar 
        ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
        : defaultAvatar;
    }
};


    return (
      <div className="bg-black min-h-screen text-white font-sans overflow-hidden relative">
        <header className="relative z-10 p-6 flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <ArrowRight className="h-8 w-8 text-white" />
                    <span className="font-bold text-2xl text-white">Victims.lol</span>
                </div>

                <nav className="hidden md:flex space-x-6 items-center">
                    <a href="/" className="text-sm hover:text-gray-300 transition-colors">Home</a>
                    <a href="/discord" className="text-sm hover:text-gray-300 transition-colors">Discord</a>
                    <a href="/pricing" className="text-sm hover:text-gray-300 transition-colors">Pricing</a>
                    {user ? (
                        <div className="relative">
                            <button onClick={handleDropdownToggle} className="flex items-center space-x-2">
                                <img src={getAvatarUrl(user)} alt="Avatar" className="w-8 h-8 rounded-full" />
                                <span>{user.discord.username}</span>
                            </button>
                            {dropdownOpen && (
                                <div className="absolute right-0 mt-2 bg-[#000000B0] text-white border border-gray-700 rounded-lg shadow-lg z-20 w-48">
                                    <div className="p-2 border-b border-gray-700">
                                        <p className="text-sm text-gray-400">{user.discord.username}</p>
                                    </div>
                                    <a href="/profile" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <User className="mr-2 h-4 w-4" />
                                        Your Profile
                                    </a>
                                    <a href="/dashboard" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <Settings className="mr-2 h-4 w-4" />
                                        Dashboard
                                    </a>
                                    <a href="/copy-profile-url" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <Copy className="mr-2 h-4 w-4" />
                                        Copy your page
                                    </a>
                                    <a href="/logout" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800 text-red-500">
                                        <LogOut className="mr-2 h-4 w-4" />
                                        Logout
                                    </a>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <a href="/login" className="text-sm hover:text-gray-300 transition-colors">Login</a>
                            <button className="bg-white text-black text-sm px-6 py-3 rounded-full hover:bg-gray-200 transition-all transform hover:scale-105 font-semibold">
                                Sign Up Free
                            </button>
                        </>
                    )}
                </nav>

                <button className="md:hidden text-white" onClick={() => setMenuOpen(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 5h14a1 1 0 110 2H3a1 1 0 110-2zm0 4h14a1 1 0 110 2H3a1 1 0 110-2zm0 4h14a1 1 0 110 2H3a1 1 0 110-2z" clipRule="evenodd" />
                    </svg>
                </button>
        </header>

        {menuOpen && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-90 p-6 md:hidden z-20 flex flex-col space-y-6 items-start">
                    <button className="self-end text-white text-6xl" onClick={() => setMenuOpen(false)}>
                        &times;
                    </button>

                    <nav className="flex flex-col space-y-6 w-full mb-6 text-center">
                        <a href="/" className="text-lg hover:text-gray-300 transition-colors">Home</a>
                        <a href="/discord" className="text-lg hover:text-gray-300 transition-colors">Discord</a>
                        <a href="/pricing" className="text-lg hover:text-gray-300 transition-colors">Pricing</a>
                    </nav>

                    <div className="flex flex-col space-y-4 w-full">
                        {user ? (
                            <div className="relative flex flex-col items-center space-y-4">
                               <button onClick={handleDropdownToggle} className="flex items-center space-x-2">
                                <img src={getAvatarUrl(user)} alt="Avatar" className="w-11 h-11 rounded-full" />
                                <span style={{
                                    fontSize: '1.3rem',
                                }}>{user.discord.username}</span>
                              </button>
                              {dropdownOpen && (
                                <div className="absolute right-0 mt-2 bg-[#000000B0] text-white border border-gray-700 rounded-lg shadow-lg z-20 w-48">
                                    <div className="p-2 border-b border-gray-700">
                                        <p className="text-sm text-gray-400">{user.discord.username}</p>
                                    </div>
                                    <a href="/profile" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <User className="mr-2 h-4 w-4" />
                                        Your Profile
                                    </a>
                                    <a href="/dashboard" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <Settings className="mr-2 h-4 w-4" />
                                        Dashboard
                                    </a>
                                    <a href="/copy-profile-url" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <Copy className="mr-2 h-4 w-4" />
                                        Copy your page
                                    </a>
                                    <a href="/logout" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800 text-red-500">
                                        <LogOut className="mr-2 h-4 w-4" />
                                        Logout
                                    </a>
                                </div>
                            )}
                            </div>
                        ) : (
                            <>
                                <button className="bg-white text-black text-lg px-6 py-3 rounded-full hover:bg-gray-200 transition-all transform hover:scale-105 font-semibold w-full">
                                    Sign Up Free
                                </button>
                                <a href="/login" className="text-lg bg-transparent border border-white text-white py-2 px-6 rounded-full text-center hover:bg-white hover:text-black transition-colors w-full">
                                    Login
                                </a>
                            </>
                        )}
                    </div>
                </div>
        )}

        <div className="text-white">
          <SobreNos />
        </div>
      </div>
        
    );
};

export default HomePage;
import '../css/Pricing.css';
import React, { useState } from 'react';
import axios from 'axios';

const PricingSection = ({ user, setUser }) => {
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const fetchUser = async () => {
    try {
      const response = await axios.get('https://api-auth.victims.lol/api/me', { withCredentials: true });
      setUser(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePurchase = async (plan) => {
    try {
      const response = await fetch('https://api-auth.victims.lol/payment/vip', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: user.discord.id,
          value: plan.value,
          vip: plan.name,
        }),
      });

      if (!response.ok) {
        throw new Error('Erro ao processar pagamento');
      }

      const data = await response.json();
      const infos = { 
        data, 
        value: plan.value 
      };
      setPaymentInfo(infos);
      setShowPopup(true);

      checkPaymentStatus(data.payment.payment_id, plan);
    } catch (error) {
      console.error(error);
    }
  };

  const checkPaymentStatus = (paymentId, plan) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`https://api-auth.victims.lol/payment/status/${paymentId}`);
        const result = await response.json();

        if (response.ok) {
          setPaymentStatus(result.status);

          if (result.status === 'approved') {
            clearInterval(intervalId);
            setShowPopup(true)
            fetchUser()

            await fetch('https://api-auth.victims.lol/payment/approved', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                id: user.discord.id,
                value: plan.value,
                vip: plan.name,
              }),
            });
          } else if (result.status === 'rejected') {
            clearInterval(intervalId);
          }
        }
      } catch (error) {
        console.error('Erro ao verificar o status do pagamento:', error);
        clearInterval(intervalId);
      }
    }, 5000); 
  };

  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <section className="pricing-section">
      <div className="pricing-cards">
        <div className="card">
          <h2>Free</h2>
          <p className="price">0 BRL <span>/lifetime</span></p>
          <ul>
            <li>Basic Customization</li>
            <li>3 Users on Site</li>
            <li>Basic Effects</li>
            <li>Add Your Friends</li>
            <li>Personalized Music</li>
          </ul>
          {user ? (
            <button>Get Started</button>
          ) : (
            <button onClick={() => window.location.href = '/login'}>Sign Up Free</button>
          )}
        </div>

        <div className="card diamond">
          <h2>Diamond</h2>
          <p className="price diamond">24.99 BRL <span>/monthly</span></p>
          <ul>
            <li>All Gold Benefits</li>
            <li>8 Users on Site</li>
            <li>FavIcon</li>
            <li>Page Description Embed</li>
            <li>Upload File 30Mb</li>
            <li>Studio Create Access</li>
          </ul>
          {user ? (
            user.data.current_premium === 'diamond' ? (
              <button>Current Subscription</button>
            ) : (
              <button onClick={() => handlePurchase({ name: 'diamond', value: 25 })}>Purchase</button>
            )
          ) : (
            <button>Sign Up</button>
          )}
        </div>

        <div className="card gold">
          <h2>Gold</h2>
          <p className="price">14.99 BRL <span>/monthly</span></p>
          <ul>
            <li>Exclusive Badge</li>
            <li>6 Users on Site</li>
            <li>Animated Background</li>
            <li>Custom Cursor</li>
            <li>Customization of Your Avatar</li>
          </ul>
          {user ? (
            user.data.current_premium === 'gold' ? (
              <button>Current Subscription</button>
            ) : user.data.current_premium === 'diamond' ? (
              <button>Subscription</button>
            ) : (
              <button onClick={() => handlePurchase({ name: 'gold', value: 15 })}>Purchase</button>
            )
          ) : (
            <button onClick={() => window.location.href = '/login'}>Sign Up</button>
          )}
        </div>
      </div>

      {showPopup && paymentInfo && paymentStatus !== 'approved' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-[#111] p-6 rounded-lg shadow-lg max-w-3xl w-full mx-4">
            <h3 className="text-lg font-semibold text-white mb-4 text-center">Guia de pagamento</h3>
            
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center lg:space-x-10">
              <div className="w-full lg:w-1/2">
                <p className="text-white/80 text-left mb-4">
                  1. Abra o aplicativo do seu banco e encontre a opção de pagamento 'Pix'.<br />
                  2. Digitalize o código QR usando sua aplicação bancária.<br />
                  3. Confirme as informações e conclua a compra.
                </p>
                <p className="text-white/80 text-left mb-4">
                  Como alternativa, você pode copiar o código Pix e colá-lo diretamente no aplicativo do seu banco.
                </p>

                <div className="bg-[#222] text-white text-sm p-2 rounded-lg flex justify-between items-center mb-4">
                  <span className="truncate">{paymentInfo.data.payment.copy_paste}</span>
                  <button
                    className="bg-transparent hover:text-blue-400"
                    onClick={() => copyToClipboard(paymentInfo.data.payment.copy_paste)}
                  >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17v1a2 2 0 002 2h6a2 2 0 002-2v-1M8 7v-1a2 2 0 012-2h6a2 2 0 012 2v1m-2 10H6a2 2 0 01-2-2V7a2 2 0 012-2h8a2 2 0 012 2v8a2 2 0 01-2 2z" />
                    </svg>
                  </button>
                </div>

                {copySuccess && (
                  <div className="text-green-500 text-sm mt-4">
                    Código copiado com sucesso!
                  </div>
                )}
              </div>

              <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mt-4 lg:mt-0">
                <img
                  className="mx-40px"
                  style={{ width: "200px", height: "200px", maxWidth: "100%" }}
                  src={`data:image/png;base64,${paymentInfo.data.payment.qr_code}`}
                  alt="QR Code"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showPopup && paymentStatus === 'approved' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-[#111] p-6 rounded-lg shadow-lg max-w-3xl w-full mx-4">
            <h3 className="text-lg font-semibold text-white mb-4 text-center">Pagamento aprovado!</h3>
            <p className="text-white text-center mb-4">
              Pagamento aprovado. Suas vantagens já foram aplicadas.
            </p>
            <div className="flex justify-center">
              <button 
                onClick={handleClosePopup}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PricingSection;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserCard = ({ user, defaultAvatar }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/${user.page_url}`);
  };

  return (
    <div
      onClick={handleCardClick}
      className="bg-gradient-to-br from-black to-[#542b55] rounded-3xl p-4 shadow-lg flex items-center space-x-4 w-full max-w-xs mx-auto mb-4 transform transition-all hover:scale-105 cursor-pointer"
    >
      <img
        src={user.discordUser?.avatarURL || defaultAvatar}
        alt={`${user.discordUser?.username}'s avatar`}
        className="w-16 h-16 rounded-xl object-cover ring-2 ring-[#542b55]"
      />
      <div className="flex flex-col space-y-1">
        <div className="flex items-center space-x-2">
          <h3 className="text-lg font-bold text-white truncate max-w-[120px]">
            {user.discordUser?.globalName?.slice(0,10) || user.discordUser.username.slice(0,10)}
          </h3>
          <span className="text-gray-300 text-sm">・{user.views} views</span>
        </div>
        <p className="text-xs text-[#a681b3] truncate max-w-[150px]">
          victims.lol/{user.page_url}
        </p>
      </div>
    </div>
  );
};

const TopUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const defaultAvatar = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/default-avatar-1728386502060.png';

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await axios.get('https://api-auth.victims.lol/api/top/views');
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar usuários top:", error);
        setError("Não foi possível carregar o ranking");
        setLoading(false);
      }
    };

    fetchTopUsers();
  }, []);

  if (loading) return (
    <div className="bg-black text-white text-center p-10">
      Carregando ranking...
    </div>
  );

  if (error) return (
    <div className="bg-black text-red-500 text-center p-10">
      {error}
    </div>
  );

  return (
    <div className="bg-black py-12 px-4">
      <h2 className="text-4xl font-bold text-white text-center mb-10 tracking-wider">
        Views Ranking
      </h2>

      <div className="flex flex-wrap justify-center gap-6 mb-6">
        {users.slice(0, 6).map((user, index) => (
          <UserCard
            key={index}
            user={user}
            defaultAvatar={defaultAvatar}
          />
        ))}
      </div>

      <div className="flex flex-wrap justify-center gap-6">
        {users.slice(6, 11).map((user, index) => (
          <UserCard
            key={index}
            user={user}
            defaultAvatar={defaultAvatar}
          />
        ))}
      </div>
    </div>
  );
}

export default TopUsers;

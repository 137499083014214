import React from 'react';
import { FaPinterestP, FaLastfm, FaKickstarterK, FaInstagram, FaTwitter, FaGithub, FaTiktok, FaSpotify, FaTwitch, FaLinkedin, FaGitlab } from 'react-icons/fa';

const icons = {
    instagram: FaInstagram,
    twitter: FaTwitter,
    github: FaGithub,
    tiktok: FaTiktok,
    spotify: FaSpotify,
    twitch: FaTwitch,
    pinterest: FaPinterestP,
    linkedin: FaLinkedin,
    gitlab: FaGitlab,
    lastfm: FaLastfm,
    kick: FaKickstarterK
};

const IconCard = ({ type, link, color }) => {
  const IconComponent = icons[type];
  const bgColor = color ? color : "#d9d9d970"

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center w-16 h-16 rounded-full transition-colors duration-300" 
      style={{
        background: bgColor
      }}
     >
    <IconComponent className="text-white hover:brightness-125" size={32} />
</a>
  );
};

export default IconCard;

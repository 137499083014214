import React, { useState, useEffect, useRef } from 'react';
import { Home, Users, Star, CreditCard, Pencil, Settings, Upload, MoreHorizontal, SwatchBook, LockKeyhole, X } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/Customize.css';
import axios from 'axios'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const Input = ({ label, placeholder, type, value, onChange }) => (
  <div className="flex flex-col mb-4">
    <label className="mb-2" style={{ color: "#C4C4C4" }}>{label}</label>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="text-white rounded-md p-2 focus:outline-none focus:ring-1 focus:ring-gray-600"
      style={{ background: "#121212", border: "2px solid #151515" }}
    />
  </div>
);

const Select = ({ label, children, value, onChange }) => (
  <div className="flex flex-col mb-4">
    <label className="text-gray-400 mb-2">{label}</label>
    <select
      className="text-white rounded-md p-2 focus:outline-none focus:ring-1 focus:ring-gray-600"
      value={value}
      onChange={onChange}
      style={{ background: "#121212", border: "2px solid #151515" }}>
      {children}
    </select>
  </div>
);

const ToggleSwitch = ({ label, checked, onChange }) => (
  <div className="flex flex-col mb-4">
    <label className="text-base mb-1 font-medium text-gray-300">{label}</label>
    <div className="relative w-[75px] h-8">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="sr-only"
      />
      <div
        className={`block w-full h-full rounded-full transition duration-200 ease-in-out cursor-pointer ${
          checked ? 'bg-gradient-to-r from-black to-[#311F31]' : 'bg-gray-600'
        }`}
        style={{
          border: "2px solid #151515",
        }}
        onClick={onChange}
      />
      <div
        className={`absolute top-0.5 left-0.5 w-7 h-7 bg-white rounded-full transform transition-transform duration-200 ease-in-out ${
          checked ? 'translate-x-12' : ''
        }`}
        style={{
          border: "2px solid #ddd",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3), inset 0px -1px 3px rgba(0, 0, 0, 0.2)"
        }}
      />
    </div>
  </div>
);

const Customize = () => {
  const { user, loading, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pageName, setPageName] = useState('');
  const [pageUrlPage, setUrlPage] = useState('');
  const [pageButtonPage, setButtonPage] = useState('');
  const [pageDescriptionPage, setDescriptionPage] = useState('');
  const [pageLayout, setPageLayout] = useState('');
  const [pageEffects, setPageEffects] = useState('');

  useEffect(() => {
    document.title = "victims.lol | customize";

    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';

    const existingLink = document.querySelector("link[rel*='icon']");
    if (existingLink) {
      document.head.removeChild(existingLink);
    }

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const [isToggledCover, setIsToggledCover] = useState(false);
  const [isToggledSocials, setIsToggledSocials] = useState(false);
  const [isToggledViews, setIsToggledViews] = useState(false);

  useEffect(() => {
    if (user) {
      setIsToggledCover(user.data.page_cover || false);
      setIsToggledSocials(user.data.socials_links || false);
      setIsToggledViews(user.data.profile_views || false);
    }
  }, [user]);

  const handleToggleCover = async () => {
    setIsToggledCover(prev => !prev);
    await saveChanges("cover", !isToggledCover);
  };

  const handleToggleSocials = async () => {
    setIsToggledSocials(prev => !prev);
    await saveChanges("socials", !isToggledSocials);
  };

  const handleToggleViews = async () => {
    setIsToggledViews(prev => !prev);
    await saveChanges("profile_views", !isToggledViews);
  };

  const saveChanges = async (type, value) => {
    try {
      const routeMap = {
        cover: 'https://api-auth.victims.lol/api/save/cover',
        profile_views: 'https://api-auth.victims.lol/api/save/views-use',
        socials: 'https://api-auth.victims.lol/api/save/socials-use',
      };

      const response = await fetch(routeMap[type], {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: user, value: value }),
      });

      if (response.ok) {
        toast.success(`${type === 'profile_views' ? `Display Views` : type} saved successfully!`);
      } else {
        toast.error(`Failed to save ${type}.`);
      }
    } catch (error) {
      toast.error('An error occurred.');
    }
  };

  const handleSaveChanges = async () => {

    if (pageName.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/name', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageName, user }),
        });

        if (response.ok) {
          toast.success('Page name saved successfully!');
          setPageName('');
          fetchUser();
        } else {
          toast.error('Failed to save page name.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }

    if (pageUrlPage.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/url', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageUrlPage, user }),
        });

        if (response.ok) {
          toast.success('Page URL saved successfully!');
          setUrlPage('');
          fetchUser();
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.message || 'Page URL is already in use.');
        } else {
          toast.error('Failed to save page URL.');
        }
      } catch (error) {
        toast.error('An error occurred while saving the page URL.');
      }
    }

    if (pageButtonPage.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/button', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageButtonPage, user }),
        });

        if (response.ok) {
          toast.success('Button saved successfully!');
          setButtonPage('');
          fetchUser();
        } else {
          toast.error('Failed to save button link.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }

    if (pageDescriptionPage.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/description', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageDescriptionPage, user }),
        });

        if (response.ok) {
          toast.success('Description saved successfully!');
          setButtonPage('');
          fetchUser();
        } else {
          toast.error('Failed to save description page.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }

    if (pageLayout.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/layout', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageLayout, user }),
        });

        if (response.ok) {
          toast.success('Layout saved successfully!');
          fetchUser();
        } else {
          toast.error('Failed to save Layout page.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }

    if (pageEffects.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/effects', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageEffects, user }),
        });

        if (response.ok) {
          toast.success('Effect saved successfully!');
          fetchUser();
        } else {
          toast.error('Failed to save Effect page.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get('https://api-auth.victims.lol/api/me', { withCredentials: true });
      setUser(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout')
    fetchUser()
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleSocialsClick = () => {
    navigate('/dashboard/socials');
  };

  const handleUsersClick = () => {
    navigate('/dashboard/users');
  };

  const handleThemeClick = () => {
    navigate('/dashboard/themes')
  };

  const handlePlansClick = () => {
    navigate('/pricing');
  };

  const handleAdminClick = () => {
    navigate('/dashboard/admin');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p>Loading...</p>
      </div>
    );
  }

  const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";

    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar
          ? `https://api.victims.lol/api/avatar/${user.discord.id}`
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar
        ? `https://api.victims.lol/api/avatar/${user.discord.id}`
        : defaultAvatar;
    }
};


  return (
    <div className="flex h-screen text-white" style={{ background: "#000000" }}>
     <button
        className="block md:hidden fixed top-4 left-4 bg-gray-800 p-2 rounded"
        style={{ width: "40px" }}
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? 'X' : '☰'}
      </button>

      <div
        className={`w-64 bg-opacity-80 backdrop-filter backdrop-blur-lg p-4 flex flex-col overflow-auto fixed md:static transform transition-transform duration-300 z-30 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 h-full`}
        style={{ borderRadius: "0 20px 20px 0", background: "rgba(23, 23, 23, 0.8)" }}
      >
        <div className="mb-8 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={getAvatarUrl(user)}
              alt="User"
              className="w-12 h-12 rounded-full mr-3"
            />
            <div>
              <h2 className="text-sm font-bold text-gray-300" style={{ fontSize: '17px' }}>
                Welcome, {user.discord.globalName || user.discord.username}
              </h2>
              <p className="text-sm text-gray-400">
                {user.data.page_url ? `victims.lol/${user.data.page_url}` : 'Não definido.'}
              </p>
            </div>
          </div>
          <button className="text-gray-300 hover:text-gray-500 transition-all md:hidden" onClick={toggleSidebar}>
            X
          </button>
        </div>
        <hr className="mb-6 transparent-hr" />
        <nav className="flex-grow">
          <SidebarLink icon={<Home />} label="Dashboard" onClick={handleDashboardClick} />
          <SidebarLink icon={<Pencil />} label="Customize" active />
          <SidebarLink icon={<Star />} label="Social Links" onClick={handleSocialsClick} />
          <SidebarLink icon={<Users />} label="Users" onClick={handleUsersClick} />
          <SidebarLink icon={<SwatchBook />} label="Themes" onClick={handleThemeClick} />
          <SidebarLink icon={<CreditCard />} label="Plans" onClick={handlePlansClick} />
          {(user.data.team_role === "owner" || user.data.team_role === "staff") && (
           <SidebarLink icon={<Settings />} label="Admin" onClick={handleAdminClick} />
          )}
        </nav>
        <div className="mt-auto">
          <div className="flex items-center justify-between p-3" style={{
            background: "rgb(20,20,20)",
            borderRadius: "40px"
          }}>
            <div className="flex items-center">
              <img
                src={getAvatarUrl(user)}
                alt="User"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <h3 className="text-sm font-semibold">{user.discord.globalName || user.discord.username}</h3>
              </div>
            </div>
            <div className="relative">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-300 hover:text-white focus:outline-none"
              >
                <MoreHorizontal size={35} style={{
                  marginTop: "4px"
                }} />
              </button>
              {isMenuOpen && (
                <div className="absolute right-0 bottom-full mb-2 w-48 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Home</a>
                    <button
                     onClick={handleLogout}
                     className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-600"
                     role="menuitem"
                    >
                     Logout
                    </button>

                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow p-8 overflow-y-auto">
  <h1 className="text-2xl font-bold mb-6" style={{ color: "#C4C4C4", marginTop: "25px" }}>Assets Upload</h1>

  <div className="p-6 rounded-lg mb-6" style={{ background: "rgb(17,17,17)" }}>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 place-items-center">
  <AssetUploader
    title="Background"
    url="https://api-auth.victims.lol/api/save/background"
    user={user}
    fetchUser={fetchUser}
    assetType="page_background"
    accept="image/jpeg, image/png, image/gif"
  />
  {(user.data.current_premium === "gold" || user.data.current_premium === "diamond") ? (
  <AssetUploader
    title="Custom Cursor"
    url="https://api-auth.victims.lol/api/save/curso"
    user={user}
    fetchUser={fetchUser}
    assetType="page_curso"
    accept="image/jpeg, image/png"
  />
  ) : (
  <AssetUploaderLock />
  )}
  {(user.data.current_premium === "gold" || user.data.current_premium === "diamond") ? (
  <AssetUploader
    title="Profile Avatar"
    url="https://api-auth.victims.lol/api/save/avatar"
    user={user}
    fetchUser={fetchUser}
    assetType="user_avatar"
    accept="image/jpeg, image/png, image/gif"
  />
  ) : (
  <AssetUploaderLock />
  )}
  <AssetUploader
    title="Audio"
    url="https://api-auth.victims.lol/api/save/audio"
    user={user}
    fetchUser={fetchUser}
    assetType="page_song"
    accept="audio/mpeg"
  />
  {(user.data.current_premium === "diamond") ? (
  <AssetUploader
    title="Favicon"
    url="https://api-auth.victims.lol/api/save/favicon"
    user={user}
    fetchUser={fetchUser}
    assetType="page_favicon"
    accept="image/jpeg, image/png"
  />
  ) : (
  <AssetUploaderLock />
  )}
   </div>
  </div>

  {(user.data.current_premium !== "gold" && user.data.current_premium !== "diamond") && (
    <div className="p-4 rounded-lg mb-6 text-center premium" style={{ background: "linear-gradient(269deg, #9849AC 3.08%, #3E1E46 128.69%)" }}>
      <p className="text-white">Want exclusive features? Unlock more with ❤️ Premium</p>
    </div>
  )}

  <h1 className="text-2xl font-bold mb-6" style={{ color: "#C4C4C4" }}>General Customization</h1>

  <div className="p-6 rounded-lg mb-6" style={{ background: "rgb(17,17,17)" }}>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <div className="flex flex-col">
        <Input label="Page Name" placeholder="Enter your page name" value={pageName} onChange={(e) => setPageName(e.target.value)} />
        <Input label="Page URL" placeholder="Enter your page URL" value={pageUrlPage} onChange={(e) => setUrlPage(e.target.value)}/>
        <Input label="Button Link" placeholder="Enter button link" value={pageButtonPage} onChange={(e) => setButtonPage(e.target.value)} />
      </div>

      <div className="flex flex-col">

      <Input label="Description" placeholder="Enter your description page" value={pageDescriptionPage} onChange={(e) => setDescriptionPage(e.target.value)} />

        <Select label="Select Layout" value={pageLayout} onChange={(e) => setPageLayout(e.target.value)} >
          <optgroup label="Layouts">
            {(!user.data.profile_layout || user.data.profile_layout === "1") && (
             <>
              <option value="1">Layout 1</option>
              <option value="2">Layout 2</option>
              <option value="3">Layout 3</option>
             </>
            )}
            {(user.data.profile_layout === "2") && (
             <>
              <option value="2">Layout 2</option>
              <option value="1">Layout 1</option>
              <option value="3">Layout 3</option>
              </>
            )}

            {(user.data.profile_layout === "3") && (
             <>
              <option value="3">Layout 3</option>
              <option value="2">Layout 2</option>
              <option value="1">Layout 1</option>
              </>
            )}
          </optgroup>
        </Select>

        <Select label="Page Effects" value={pageEffects} onChange={(e) => setPageEffects(e.target.value)}>
          <optgroup label="Page Effects">
            <option value="none">No Effect</option>
            <option value="moon">Moon</option>
            <option value="stars">Stars</option>
            <option value="kuromi">Kuromi</option>
            <option value="hello-kitty">Hello Kitty</option>
            <option value="snowflakes">SnowFlakes</option>
          </optgroup>
        </Select>
      </div>

      <div className='flex flex-col'>
      </div>
      <p className="text-sm text-gray-500 mt-2">
            Se quiser remover, insira "@" no campo correspondente.
          </p>
    </div>
  </div>

  <h1 className="text-2xl font-bold mb-6" style={{ color: "#C4C4C4" }}>Other Customization</h1>

  <div className="p-6 rounded-lg mb-6" style={{ background: "rgb(17,17,17)" }}>
      <div className="flex" style={{ gap: "0px" }}>
        <div className="flex flex-col" style={{ marginRight: "15px" }}>
          <ToggleSwitch
            label="Page Cover"
            checked={isToggledCover}
            onChange={handleToggleCover}
          />
        </div>
        <div className="flex flex-col" style={{ marginLeft: "15px" }}>
          <ToggleSwitch
            label="Socials links"
            checked={isToggledSocials}
            onChange={handleToggleSocials}
          />
        </div>
        <div className="flex flex-col" style={{ marginLeft: "15px" }}>
          <ToggleSwitch
            label="Display view counter"
            checked={isToggledViews}
            onChange={handleToggleViews}
          />
        </div>
      </div>
    </div>


     <button
        className="relative bg-[#181818] text-white text-lg font-normal py-3 px-8 overflow-hidden"
        style={{ borderRadius: '15px' }}
        onClick={handleSaveChanges}
      >
        <div className="absolute inset-0 bg-transparent opacity-50"></div>
        <div className="relative z-10">Save Changes</div>
      </button>

      <ToastContainer />
      </div>
    </div>
  );
};

const AssetUploader = ({ title, url, user, assetType, accept, fetchUser }) => {
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(user.data[assetType] || "");
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", x: 25, y: 25, width: 50, height: 50 });
  const imageRef = useRef(null);

  const getAspectRatio = () => {
    switch (assetType) {
      case "user_avatar":
      case "page_favicon":
      case "page_curso":
        return 1;
      case "page_background":
        return 16 / 9;
      default:
        return 1;
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (imageUrl) {
        toast.error("Já existe uma imagem. Remova a imagem atual antes de fazer um novo upload.");
        return;
      }

      const allowedTypes = {
        page_background: ["image/png", "image/jpeg", "image/gif"],
        user_avatar: ["image/png", "image/jpeg", "image/gif"],
        page_favicon: ["image/png", "image/jpeg"],
        page_curso: ["image/png", "image/jpeg"],
        page_song: ["*/*"],
      };

      if (assetType !== 'page_song' && !allowedTypes[assetType]?.includes(selectedFile.type)) {
        const allowedExtensions = allowedTypes[assetType].map((type) => type.split("/")[1]).join(", ");
        toast.error(`Apenas ${allowedExtensions} são permitidos para ${title}.`);
        return;
      }

     if (assetType === "page_song") {
        handleAudioUpload(selectedFile);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
          setCropModalOpen(true);
          setCroppedImage(null);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const handleAudioUpload = async (file) => {
    setUploading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const cdnResponse = await fetch("https://cdn.victims.lol/upload", {
        method: "POST",
        body: formData,
      });

      if (!cdnResponse.ok) {
        throw new Error("Falha ao fazer upload para o CDN.");
      }

      const cdnData = await cdnResponse.json();
      const uploadedUrl = cdnData.url;

      const serverResponse = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: uploadedUrl, user }),
      });

      if (!serverResponse.ok) {
        throw new Error(`Falha ao salvar ${title} no servidor.`);
      }

      setImageUrl(uploadedUrl);
      toast.success(`${title} enviado com sucesso!`);
      fetchUser();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUploading(false);
    }
  };

  const getCroppedImg = async (image, crop, aspect) => {
    if (!crop.width || !crop.height) {
      toast.error("Selecione uma área válida para recorte.");
      return null;
    }

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const targetWidth = crop.width * scaleX;
    const targetHeight = crop.height * scaleY;

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      targetWidth,
      targetHeight,
      0,
      0,
      targetWidth,
      targetHeight
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            resolve(url);
          } else {
            reject(new Error("Erro ao criar a imagem recortada."));
          }
        },
        "image/jpeg",
        1
      );
    });
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedUrl = await getCroppedImg(imageRef.current, crop, getAspectRatio());
      setCroppedImage(croppedUrl);
    }
  };

  const handleUpload = async () => {
    if (!croppedImage) {
      toast.error("Por favor, recorte a imagem antes de continuar.");
      return;
    }

    setUploading(true);

    try {
      const response = await fetch(croppedImage);
      const blob = await response.blob();
      const file = new File([blob], "cropped-image.jpg", { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("file", file);

      const cdnResponse = await fetch("https://cdn.victims.lol/upload", {
        method: "POST",
        body: formData,
      });

      if (!cdnResponse.ok) {
        throw new Error("Falha ao fazer upload para o CDN.");
      }

      const cdnData = await cdnResponse.json();
      const uploadedUrl = cdnData.url;

      const serverResponse = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: uploadedUrl, user }),
      });

      if (!serverResponse.ok) {
        throw new Error(`Falha ao salvar ${title} no servidor.`);
      }

      setImageUrl(uploadedUrl);
      setCropModalOpen(false);
      toast.success(`${title} enviado com sucesso!`);
      fetchUser();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveImage = async () => {
    try {
      const serverResponse = await fetch(`${url}/remove`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user }),
      });

      if (!serverResponse.ok) {
        toast.error("Falha ao remover a imagem.");
      } else {
        setImageUrl("");
        toast.success(`${title} removido com sucesso!`);
        fetchUser();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleContainerClick = () => {
    const fileInput = document.getElementById(`file-input-${assetType}`);
    fileInput?.click();
  };

  const getImageUrl = (url) => {
    return `https://api-auth.victims.lol/api/proxy?url=${url}`;
  };

  const imageUrlToDisplay = assetType === "page_song"
    ? 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/icons8-song-96-1728227167548.png'
    : getImageUrl(imageUrl);

  const styleDisplay = assetType === "page_song" ? {
    width: '55%',
    height: '65%',
    objectFit: 'cover',
    marginLeft: "35px",
    marginTop: "25px"
  } : {
    width: '100%',
    borderRadius: "5px",
    height: '100%',
    objectFit: 'cover',
  };

  return (
    <>
      <div
        className="flex flex-col items-center p-4 rounded-lg relative"
        style={{
          backgroundColor: "rgb(15,15,15)",
          border: "2px solid #181818",
          width: "200px",
          height: "180px",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={handleContainerClick}
      >
        {imageUrl ? (
          <div className="relative w-full h-full">
            <img src={imageUrlToDisplay} alt={title} style={styleDisplay} />
            <button
              onClick={handleRemoveImage}
              className="absolute top-1 right-1 text-red-500 bg-black bg-opacity-70 rounded-xl w-10 h-10 flex items-center justify-center"
            >
              X
            </button>
          </div>
        ) : (
          <>
            <Upload className="text-gray-400 mt-10" size={32} />
            <h3 className="text-white mt-2">{title}</h3>
            <input
              id={`file-input-${assetType}`}
              type="file"
              onChange={handleFileChange}
              accept={accept}
              className="hidden"
            />
          </>
        )}
      </div>

      {cropModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4">
    <div className="bg-neutral-900 rounded-2xl p-8 w-full max-w-xl shadow-2xl relative border border-neutral-700">
      <button
        onClick={() => setCropModalOpen(false)}
        className="absolute top-4 right-4 text-neutral-400 hover:text-white transition-colors"
      >
        <X size={28} className="stroke-[1.5]" />
      </button>

      <h2 className="text-white text-2xl font-bold mb-6 text-center">
        Ajuste sua Imagem
      </h2>

      <div className="flex flex-col items-center space-y-6">
        <div
          className="w-full bg-neutral-800/50 rounded-xl"
          style={{ maxHeight: 'calc(100vh - 150px)' }}
        >
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={makeClientCrop}
            aspect={getAspectRatio()}
            className="w-full h-full"
          >
            <img
              src={image}
              ref={imageRef}
              alt="Crop"
              className="object-contain w-full h-full rounded-xl"
            />
          </ReactCrop>
        </div>

        <button
          onClick={handleUpload}
          className="w-full bg-neutral-800 text-white font-semibold
          px-6 py-3 rounded-xl hover:bg-neutral-700
          transition-colors duration-300 shadow-md"
        >
          {uploading ? 'Enviando' : 'Enviar Imagem'}
        </button>
      </div>
    </div>
  </div>
)}

    </>
  );
};


const AssetUploaderLock = () => {

  return (
    <div
      className="flex flex-col items-center p-4 rounded-lg relative"
      style={{
        backgroundColor: 'rgb(15,15,15)',
        backdropFilter: 'blur(8px)',
        border: "2px solid #181818",
        width: '200px',
        height: '180px',
        overflow: 'hidden',
        cursor: 'not-allowed',
        justifyContent: 'center'
      }}
    >

      <LockKeyhole className="text-gray-400 mb-2" size={32} />
    </div>
  );
};

const SidebarLink = ({ icon, label, onClick, active }) => (
  <div className={`flex items-center p-3 rounded-lg mb-2 ${active ? 'violet bg-opacity-50' : 'hover:bg-white hover:bg-opacity-30'} transition-all transform hover:scale-105`} onClick={onClick}>
    {React.cloneElement(icon, { size: 20, className: 'text-gray-300' })}
    <span className="ml-3 font-medium">{label}</span>
  </div>
);

export default Customize;

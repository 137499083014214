import React from 'react';
import { FaShieldAlt, FaWallet, FaRocket } from 'react-icons/fa';

const FeaturesSection = () => {
  return (
    <div className="bg-gradient-to-r from-black to-[#542b55] p-8 text-white flex flex-col lg:flex-row mt-10">
      <div className="lg:w-1/2 flex flex-col justify-center mb-6 lg:mb-0">
        <h2 className="text-3xl font-bold mb-4">Your amazing page here...</h2>
        <p className="text-lg">
          We have a well-encrypted and safe security system for the users and a flow of purchase transactions that are easy and fast.
        </p>
      </div>
      <div className="lg:w-1/2 flex flex-col lg:flex-row lg:space-x-4">
        <div className="flex flex-col w-full lg:w-1/3 mb-4 lg:mb-0">
          <div className="flex items-start border-b lg:border-r border-white border-opacity-20 pb-4 lg:pb-0 lg:pr-4">
            <FaShieldAlt className="text-3xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Encrypted Security</h3>
              <p className="mt-1 text-sm">We have a well-encrypted and safe security system for the users.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/3 mb-4 lg:mb-0">
          <div className="flex items-start border-b lg:border-r border-white border-opacity-20 pb-4 lg:pb-0 lg:pr-4">
            <FaWallet className="text-3xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Easy & Fast Transaction</h3>
              <p className="mt-1 text-sm">We have a flow of purchase transactions that are easy and fast.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/3">
          <div className="flex items-start">
            <FaRocket className="text-3xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Quick Delivery</h3>
              <p className="mt-1 text-sm">Our transactions are quick and efficient, ensuring a smooth experience.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TopUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get('https://api-auth.victims.lol/api/top/views')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error("Ocorreu um erro ao buscar os dados: ", error);
      });
  }, []);

  return (
    <div className="p-8 text-center bg-[#000000] mt-10">
      <h2 className="text-4xl font-bold text-[#ffffff] mb-10">Views Ranking</h2>

      <div className="overflow-x-auto whitespace-nowrap">
        <div className="flex flex-col md:flex-row md:space-x-6 mb-6">
          {users.slice(0, 5).map((user, index) => (
            <div 
              key={index} 
              className="inline-block rounded-full p-4 text-[#542b55] shadow-lg flex items-center space-x-4 mx-auto w-full md:w-64 mb-4" // Margem inferior adicionada
              style={{
                background: "linear-gradient(135deg, #000000, #542b55)",
                margin: "5px auto",
                borderRadius: "30px",
              }}
            >
              <img 
                src={user.discordUser?.avatarURL || 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/default-avatar-1728386502060.png'} 
                alt={`${user.discordUser?.username}'s avatar`} 
                className="w-[50px] h-[50px] rounded-xl object-cover"
              />
              <div className="flex flex-col items-start">
                <div className="flex items-center space-x-1">
                  <h3 className="text-lg font-semibold text-white">{user.discordUser?.globalName ? user.discordUser.globalName.slice(0,6) : user.discordUser.username.slice(0,6)}</h3>
                  <p className="text-gray-300">・{user.views}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm text-[#542b55]">victims.lol/{user.page_url}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col md:flex-row md:space-x-6">
          {users.slice(5, 9).map((user, index) => (
            <div 
              key={index} 
              className="inline-block rounded-full p-4 text-[#542b55] shadow-lg flex items-center space-x-4 mx-auto w-full md:w-64 mb-4" 
              style={{
                background: "linear-gradient(135deg, #000000, #542b55)",
                margin: "5px auto",
                borderRadius: "30px",
              }}
            >
              <img 
                src={user.discordUser?.avatarURL || 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/default-avatar-1728386502060.png'} 
                alt={`${user.discordUser?.username}'s avatar`} 
                className="w-[50px] h-[50px] rounded-xl object-cover"
              />
              <div className="flex flex-col items-start">
                <div className="flex items-center space-x-1">
                  <h3 className="text-lg font-semibold text-white">{user.discordUser?.globalName ? user.discordUser.globalName.slice(0,6) : user.discordUser.username.slice(0,6)}</h3>
                  <p className="text-gray-300">・{user.views}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm text-[#542b55]">victims.lol/{user.page_url}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopUsers;

import React, { useState, useEffect } from 'react';
import { Home, Users, Star, CreditCard, Pencil, Settings, MoreHorizontal, SwatchBook } from 'lucide-react';
import { FaLink, FaUsers, FaEye } from 'react-icons/fa';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import '../css/Dashboard.css';

const Dashboard = () => {
  const { user, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [configuredUsersSum, setConfiguredUsersSum] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    document.title = "victims.lol | dashboard";

    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';

    const existingLink = document.querySelector("link[rel*='icon']");
    if (existingLink) {
      document.head.removeChild(existingLink);
    }

    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (user && user.data) {
      const sum = [user.data.user1, user.data.user2, user.data.user3, user.data.user4, user.data.user5, user.data.user6, user.data.user7];
  
      const filteredSum = sum.filter(item => item !== null && item !== undefined && item !== "");
  
      const numberCount = filteredSum.length;
  
      console.log(numberCount); 
      setConfiguredUsersSum(numberCount); 
    }
  }, [user]);  

  const handleCustomizeClick = () => {
    navigate('/dashboard/customize');
  };

  const handleUsersClick = () => {
    navigate('/dashboard/users');
  };
  
  const handleThemeClick = () => {
    navigate('/dashboard/themes');
  };

  const handlePlansClick = () => {
    navigate('/pricing');
  };

  const handleAdminClick = () => {
    navigate('/dashboard/admin');
  };

  const handleSocialsClick = () => {
    navigate('/dashboard/socials');
  };

  const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";
    
    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar 
          ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar 
        ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
        : defaultAvatar;
    }
};


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    navigate('/logout')
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen text-white" style={{ background: "#000000" }}>
      <button
        className="block md:hidden fixed top-4 left-4 bg-gray-800 p-2 rounded"
        style={{ width: "40px" }}
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? 'X' : '☰'}
      </button>

      <div
        className={`w-64 bg-opacity-80 backdrop-filter backdrop-blur-lg p-4 flex flex-col overflow-auto fixed md:static transform transition-transform duration-300 z-30 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 h-full`}
        style={{ borderRadius: "0 20px 20px 0", background: "rgba(23, 23, 23, 0.8)" }}
      >
        <div className="mb-8 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={getAvatarUrl(user)}
              alt="User"
              className="w-12 h-12 rounded-full mr-3"
            />
            <div>
              <h2 className="text-sm font-bold text-gray-300" style={{ fontSize: '17px' }}>
                Welcome, {user.discord.globalName || user.discord.username}
              </h2>
              <p className="text-sm text-gray-400">
                {user.data.page_url ? `victims.lol/${user.data.page_url}` : 'Não definido.'}
              </p>
            </div>
          </div>
          <button className="text-gray-300 hover:text-gray-500 transition-all md:hidden" onClick={toggleSidebar}>
            X
          </button>
        </div>
        <hr className="mb-6 transparent-hr" />
        <nav className="flex-grow">
          <SidebarLink icon={<Home />} label="Dashboard" active />
          <SidebarLink icon={<Pencil />} label="Customize" onClick={handleCustomizeClick} />
          <SidebarLink icon={<Star />} label="Social Links" onClick={handleSocialsClick} />
          <SidebarLink icon={<Users />} label="Users" onClick={handleUsersClick} />
          <SidebarLink icon={<SwatchBook />} label="Themes" onClick={handleThemeClick} />
          <SidebarLink icon={<CreditCard />} label="Plans" onClick={handlePlansClick} />
          {(user.data.team_role === "owner" || user.data.team_role === "staff") && (
           <SidebarLink icon={<Settings />} label="Admin" onClick={handleAdminClick} />
          )}
        </nav>
        <div className="mt-auto">
          <div className="flex items-center justify-between p-3" style={{
            background: "rgb(20,20,20)",
            borderRadius: "40px"
          }}>
            <div className="flex items-center">
              <img
                src={getAvatarUrl(user)}
                alt="User"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <h3 className="text-sm font-semibold">{user.discord.globalName || user.discord.username}</h3>
              </div>
            </div>
            <div className="relative">
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-300 hover:text-white focus:outline-none"
              >
                <MoreHorizontal size={35} style={{
                  marginTop: "4px"
                }} />
              </button>
              {isMenuOpen && (
                <div className="absolute right-0 bottom-full mb-2 w-48 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Home</a>
                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Logout</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 p-8 overflow-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8" style={{ marginTop: "30px" }}>
          <StatCard
            icon={<FaLink />}
            title="Current URL"
            value={user.data.page_url ? `victims.lol/${user.data.page_url}` : "Não definido."}
          />
          <StatCard
            icon={<FaUsers />}
            title="Added Users"
            value={`${configuredUsersSum} configured users`}
          />
          <StatCard
            icon={<FaEye />}
            title="Page Views"
            value={user.data.views || 0}
          />
        </div>
        <div className="bg-black rounded-2xl p-8 shadow-2xl">
          <img
            src={getAvatarUrl(user)}
            alt="User"
            className="w-32 h-32 rounded-full mx-auto mb-4 shadow-lg"
          />
          <h3 className="text-center text-3xl font-bold mb-2 text-gray-300">
            {user.discord.globalName || user.discord.username}
          </h3>
          <div className="flex justify-center">
            {user.external.badges && user.external.badges.length > 0 ? (
              user.external.badges.map((emoji, index) => (
                <span key={index} className="mx-2 text-xl animate-pulse z-20">
                  <img
                    src={emoji && emoji.icon ? `https://cdn.discordapp.com/badge-icons/${emoji.icon}.png` : emoji.image}
                    alt={`Badge ${emoji.icon}`}
                    className="inline-block w-8 h-8"
                  />
                </span>
              ))
            ) : (
              <span>No badges available</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SidebarLink = ({ icon, label, onClick, active }) => (
  <div className={`flex items-center p-3 rounded-lg mb-2 ${active ? 'violet bg-opacity-50' : 'hover:bg-white hover:bg-opacity-30'} transition-all transform hover:scale-105`} onClick={onClick}>
    {React.cloneElement(icon, { size: 20, className: 'text-gray-300' })}
    <span className="ml-3 font-medium">{label}</span>
  </div>
);

const StatCard = ({ icon, title, value }) => (
  <div className="p-6 rounded-xl shadow-xl transition-all" style={{ background: "rgba(23, 23, 23, 0.8)", width: '100%' }}>
    <div className="flex flex-col items-start">
      <div className="rounded-lg p-2 mb-2" style={{ backgroundColor: "rgb(84,43,85)" }}>
        {React.cloneElement(icon, { className: "text-white", size: 20 })}
      </div>
      <h3 className="text-lg font-bold text-gray-300">{title}</h3>
      <p className="text-xl font-semibold text-gray-400">{value}</p>
    </div>
  </div>
);

export default Dashboard;
import React from 'react';
import '../css/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h4>victims<span style={{ color: "#9062b3" }}>.</span>lol</h4>
                <p>Build unique and modern bio pages to celebrate your friendships with victims.lol.</p>
            </div>
            <div className="footer-section">
                <h4>General</h4>
                <ul>
                    <li><a href="/login">Login</a></li>
                    <li><a href="/pricing">Pricing</a></li>
                </ul>
            </div>
            <div className="footer-section">
                <h4>Contact</h4>
                <ul>
                    <li><a href="/discord">Discord Server</a></li>
                    <li><a href="https://diswitter.com/victims">Diswitter Profile</a></li>
                </ul>
            </div>
            <div className="footer-section">
                <h4>Legal</h4>
                <ul>
                    <li><a href="/terms">Terms of Service</a></li>
                    <li><a href="/privacy&policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div className="footer-bottom">
                © 2024 victims.lol. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from '../hooks/useAuth'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const { user, setUser } = useAuth(); // Para salvar o usuário autenticado
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "victims.lol | login";

    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';

    const existingLink = document.querySelector("link[rel*='icon']");
    if (existingLink) {
      document.head.removeChild(existingLink);
    }

    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const errorParam = params.get('error');
    if (errorParam) {
      if (errorParam === 'suspended') {
        setError('Sua conta foi suspensa. Entre em contato com o suporte.');
      } else if (errorParam === 'auth_failed') {
        setError('Falha na autenticação. Tente novamente.');
      } else if (errorParam === 'db_error') {
        setError('Erro no banco de dados. Tente novamente mais tarde.');
      }
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api-auth.victims.lol/login', {
        email,
        password,
      });

      if (response.data.user) {
        setUser(response.data.user); // Armazena o usuário no contexto
        navigate('/dashboard'); // Redireciona para o dashboard em caso de sucesso
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Erro ao fazer login. Tente novamente.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="bg-[#1a1a1a] p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-white text-2xl font-bold mb-6 text-center">Log in to your account</h2>

        {error && <div className="mb-4 text-red-500 text-center">{error}</div>}

        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="text-white block mb-1" htmlFor="email">Email</label>
            <div className="relative">
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-10 py-2 text-white rounded-[15px] border-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                style={{
                  backgroundColor: "#333",
                  border: "2px solid #202020",
                  borderRadius: "10px",
                }}
              />
              <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
            </div>
          </div>

          <div className="mb-4">
            <label className="text-white block mb-1" htmlFor="password">Password</label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-10 py-2 text-white rounded-[15px] border-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                style={{
                  backgroundColor: "#333",
                  border: "2px solid #202020",
                  borderRadius: "10px",
                }}
              />
              <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <span
                className="absolute inset-y-0 right-3 flex items-center text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>

          <div className="mb-6 text-right">
            <a href="/forgot" className="text-blue-500 hover:underline text-sm">Forgot password?</a>
          </div>

          <button
            type="submit"
            className="w-full py-2 bg-[#181818] border-2 border-[#202020] rounded-[15px] text-white font-semibold hover:bg-gray-800 transition duration-300"
            style={{ fontSize: "17px" }}
          >
            Login
          </button>
        </form>

        <div className="my-6 flex items-center justify-center">
          <hr className="border-t border-gray-600 w-full" />
          <span className="mx-4 text-gray-400">Or</span>
          <hr className="border-t border-gray-600 w-full" />
        </div>

        <button
          onClick={() => (window.location.href = 'https://api-auth.victims.lol/auth/discord')}
          className="w-full py-2 rounded-md flex items-center justify-center gap-2 text-white font-semibold transition duration-300"
          style={{
            background: "linear-gradient(91deg, #2E4CEE 9.91%, #221EBF 53.29%, #040F75 91.56%)",
            borderRadius: "15px",
            border: "2px solid #5661f648",
            fontSize: "19px"
          }}
        >
          <FontAwesomeIcon icon={faDiscord} /> Discord
        </button>

        <div className="mt-6 text-center text-gray-400">
          Are you new to our site? 
          <a href='/login#discord' className="text-blue-500 hover:underline"> Create an account</a>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React from 'react';

const ViewsCounter = ({ views, color }) => {

  const colorUse = color ? color : "#d9d9d970"
  return (
    <div 
    className={`fixed top-10 right-4 rounded-xl px-4 flex items-center space-x-2`}style={{
      height: "45px",
      background: colorUse,
    }}>
      <div className="rounded-full color-white">
        <svg
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-white"
        >
          <path
            d="M8 3.33333C4.5 3.33333 1.5 7 1.5 8C1.5 9 4.5 12.6667 8 12.6667C11.5 12.6667 14.5 9 14.5 8C14.5 7 11.5 3.33333 8 3.33333Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <span className="text-white font-medium">{views.toLocaleString()}</span>
    </div>
  );
};

export default ViewsCounter;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Plans from '../pages/Plans';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Socials from '../pages/Socials';
import Users from '../pages/Users';
import Themes from '../pages/Themes';
import Customize from '../pages/Customize';
import { AuthProvider } from '../contexts/AuthContext';
import Profile from '../pages/Profile';
import UserProfile from '../pages/UserProfile';
import NotFound from '../pages/NotFound';
import Admin from '../pages/Admin';
import AdminUser from '../pages/AdminUser';
import TeamForm from '../pages/TeamForm';
import Team from '../pages/Team';
import TestRoute from '../pages/TestRoute';

const Redirect = () => {
  React.useEffect(() => {
    window.location.href = "https://discord.gg/erro";
  }, []);

  return null;
};

const LogoutRedirect = () => {
  React.useEffect(() => {
    window.location.href = "https://api-auth.victims.lol/logout";
  }, []);

  return null;
};

function DiscordRoute() {
  return <pre>dh=34aeb811a36bf66dcf8405d2e05020c327259d8a</pre>;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/.well-known/discord" element={<DiscordRoute />} />
          <Route path="/pricing" element={<Plans />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/customize" element={<Customize />} />
          <Route path="/dashboard/socials" element={<Socials />} />
          <Route path="/dashboard/users" element={<Users />} />
          <Route path="/dashboard/themes" element={<Themes />} />
          <Route path="/dashboard/admin" element={<Admin />} />
          <Route path="/dashboard/admin/:id/user" element={<AdminUser />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team-form" element={<TeamForm />} />
          <Route path="/discord" element={<Redirect />} />
          <Route path="/testee" element={<TestRoute />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/logout" element={<LogoutRedirect />} />
          <Route path="/:username" element={<UserProfile />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

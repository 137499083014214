import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaVolumeMute, FaVolumeUp, FaQuestionCircle } from 'react-icons/fa';

const MusicInput = ({ link, color, cover }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showCover, setShowCover] = useState(cover === true);
  const [volume, setVolume] = useState(1);
  const [notification, setNotification] = useState('');
  const [showShortcuts, setShowShortcuts] = useState(false);
  const audioRef = useRef(new Audio(link));

  useEffect(() => {
    audioRef.current.loop = true;
    audioRef.current.volume = volume;
  }, [link, volume]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const handleCoverClick = () => {
    setShowCover(false);
    audioRef.current.play();
    setIsPlaying(true);
  };

  const toggleMute = useCallback(() => {
    if (audioRef.current.muted) {
      audioRef.current.muted = false;
      setNotification('Desmutado');
    } else {
      audioRef.current.muted = true;
      setNotification('Mutado');
    }
    setTimeout(() => setNotification(''), 2000);
  }, []);

  const handleVolumeUp = useCallback(() => {
    if (volume < 1) {
      const newVolume = Math.min(volume + 0.1, 1);
      setVolume(newVolume);
      audioRef.current.volume = newVolume;
      setNotification(`Volume aumentou para ${Math.round(newVolume * 100)}%`);
      setTimeout(() => setNotification(''), 2000);
    }
  }, [volume]);

  const handleVolumeDown = useCallback(() => {
    if (volume > 0) {
      const newVolume = Math.max(volume - 0.1, 0);
      setVolume(newVolume);
      audioRef.current.volume = newVolume;
      setNotification(`Volume diminuiu para ${Math.round(newVolume * 100)}%`);
      setTimeout(() => setNotification(''), 2000);
    }
  }, [volume]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      switch (event.key) {
        case 'm':
          toggleMute();
          break;
        case 'q':
          handleVolumeUp();
          break;
        case 'e':
          handleVolumeDown();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [toggleMute, handleVolumeUp, handleVolumeDown]);

  const handleShortcutsToggle = () => {
    setShowShortcuts(!showShortcuts);
  };

  const bgColor = color ? color : "#d9d9d970";

  return (
    <div className="relative">
      {showCover ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm" style={{ zIndex: 1005 }}>
          <button
            onClick={handleCoverClick}
            className="text-white text-3xl p-4 border border-white rounded"
            style={{ background: 'transparent', border: 'none' }}
          >
            Click
          </button>
        </div>
      ) : (
        <div className="fixed flex flex-col items-center p-2 rounded-xl mt-5" style={{
          background: bgColor,
          width: "45px",
          left: "20px",
          top: "20px",
          cursor: "pointer",
          zIndex: 1000,
        }}>
          <div className="volume-container flex flex-col items-center">
            <button onClick={togglePlayPause} className="text-white mb-1" style={{ fontSize: "25px" }}>
              {isPlaying ? <FaVolumeUp /> : <FaVolumeMute />}
            </button>
          </div>
        </div>
      )}

      {notification && (
        <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 bg-black text-white p-2 rounded-md">
          {notification}
        </div>
      )}

      <div
        onClick={handleShortcutsToggle}
        className="fixed bottom-4 left-4 text-white p-3 rounded-xl cursor-pointer shadow-lg"
        style={{ zIndex: 1001, background: bgColor }}
      >
        <FaQuestionCircle size={20} />
      </div>

      {showShortcuts && (
  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 backdrop-blur-xl flex items-center justify-center" style={{ zIndex: 1002 }}>
    <div className="bg-white bg-opacity-80 text-gray-900 p-6 rounded-2xl shadow-2xl w-11/12 max-w-lg">
      <h2 className="text-2xl font-semibold mb-4 text-center text-gray-800">Atalhos</h2>
      <ul className="space-y-3 text-base text-gray-700">
        <li><span className="font-medium text-gray-800">M</span>: Mute/Desmutar</li>
        <li><span className="font-medium text-gray-800">Q</span>: Aumentar volume</li>
        <li><span className="font-medium text-gray-800">E</span>: Diminuir volume</li>
      </ul>
      <button
        onClick={handleShortcutsToggle}
        className="mt-6 bg-gray-900 text-white p-2 rounded-full w-full hover:bg-gray-800 transition duration-300"
      >
        Fechar
      </button>
    </div>
  </div>
)}

    </div>
  );
};

export default MusicInput;

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const fetchUser = async () => {
          try {
              const response = await axios.get('https://api-auth.victims.lol/api/me', { withCredentials: true });
              console.log('User response:', response.data);
              setUser(response.data);
          } catch (error) {
              setUser(null);
              console.log('Error fetching user:', error);
          } finally {
              setLoading(false);
          }
      };

      fetchUser();
  }, []);

  const login = () => {
      window.location.href = 'https://api-auth.victims.lol/auth/discord';
  };

  const logout = () => {
      axios.get('https://api-auth.victims.lol/logout').then(() => {
          setUser(null);
      });
  };

  return (
      <AuthContext.Provider value={{ user, loading, login, logout }}>
          {children}
      </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
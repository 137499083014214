import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";

const ParticlesPage = ({ data }) => {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  let particleImg = null;

  if (data.page_theme === 'hello') {
    particleImg = "https://cdn.discordapp.com/emojis/1259613285066739774.png";
  } else if (data.page_theme === 'cinnamoroll') {
    particleImg = "https://cdn.discordapp.com/emojis/978694897957757008.png";
  } else if (data.page_theme === 'moana') { 
    particleImg = "https://cdn.discordapp.com/emojis/1268630601867460731.png";
  } else if (data.page_theme === 'hollow') {
    particleImg = "https://cdn.discordapp.com/emojis/1268658335335579833.png";
  } else if (data.page_theme === 'kuromi') {
    particleImg = "https://cdn.discordapp.com/emojis/1264252830949183559.png";
  } else if (data.page_theme === 'pucca') {
    particleImg = "https://cdn.discordapp.com/emojis/1292581591612264449.png";
  } else {
    if (data.partials === 'snowflakes') {
      particleImg = "https://cdn.discordapp.com/emojis/1120102798713966652.png";
    }
    if (data.partials === 'stars') {
      particleImg = "https://cdn.discordapp.com/emojis/1248388061721526282.png";
    }
    if (data.partials === 'moon') {
      particleImg = "https://cdn.discordapp.com/emojis/1237182887460081695.gif";
    }
    if (data.partials === 'hello-kitty') {
      particleImg = "https://cdn.discordapp.com/emojis/1259613285066739774.png";
    }
    if (data.partials === 'kuromi') {
      particleImg = "https://cdn.discordapp.com/emojis/1264252830949183559.png";
    }
  }

  return (
    <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
            fpsLimit: 60,
            interactivity: {
                events: {
                    onClick: {
                        enable: false,
                        mode: "push",
                    },
                    onHover: {
                        enable: false,
                        mode: "repulse",
                    },
                    resize: false,
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff", 
                },
                move: {
                    direction: "bottom", 
                    enable: true,
                    outModes: {
                        default: "out", 
                    },
                    random: false,
                    speed: 1, 
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 800,
                    },
                    value: 45, 
                },
                opacity: {
                    value: 0.8
                },
                shape: {
                  type: "image",
                  image: {
                    src: particleImg,
                    width: 100,
                    height: 100
                  }
                },
                size: {
                    value: { min: 6, max: 13 }, 
                },
            },
            detectRetina: true,
        }}
    />
);
};

export default ParticlesPage;

import React, { useState, useEffect } from 'react';
import { Check, Plus, Trash2 } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TeamForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    specialty: '',
    experienceTime: '',
    experienceUnit: 'years',
    github: '',
    description: '',
    projects: [{ name: '', role: '', description: '' }]
  });
  const { user, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  const specialties = [
    { value: 'fullstack', label: 'Full Stack' },
    { value: 'backend', label: 'Back-end' },
    { value: 'frontend', label: 'Front-end' },
    { value: 'uiux', label: 'Designer UI/UX' },
    { value: 'graphic', label: 'Designer Gráfico' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProjectChange = (index, e) => {
    const { name, value } = e.target;
    const newProjects = formData.projects.map((project, i) => {
      if (i === index) {
        return { ...project, [name]: value };
      }
      return project;
    });
    setFormData((prev) => ({
      ...prev,
      projects: newProjects
    }));
  };

  const addProject = () => {
    if (formData.projects.length < 4) { 
      setFormData((prev) => ({
        ...prev,
        projects: [...prev.projects, { name: '', role: '', description: '' }]
      }));
    }
  };

  const removeProject = (index) => {
    if (formData.projects.length > 1) {
      setFormData((prev) => ({
        ...prev,
        projects: prev.projects.filter((_, i) => i !== index)
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.age || !formData.specialty || !formData.experienceTime) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    try {
      const response = await fetch('https://api-auth.victims.lol/api/send/form-team', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formData, user }),
      });

      if (response.ok) {
        toast.success('Formulário enviado com sucesso!');
        setFormData({
          name: '',
          age: '',
          specialty: '',
          experienceTime: '',
          experienceUnit: 'years',
          github: '',
          description: '',
          projects: [{ name: '', role: '', description: '' }]
        });
      } else {
        toast.error('Falha ao enviar o formulário.');
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar o formulário.');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black px-4 py-8 md:py-12">
    <ToastContainer />
    {user && user.data.send_form === true ? (
    <div className="flex items-center justify-center h-screen">
    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <p className="text-lg text-gray-700">Seu formulário já foi enviado, aguarde até ser aceito.</p>
    </div>
    </div>
    ) : (
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row gap-8">
      <div className="flex-1 space-y-8">
        <h1 className="text-3xl font-bold text-white mb-8 text-center md:text-left">
          Cadastro de Equipe
        </h1>

        <div>
          <h2 className="text-xl font-semibold text-white mb-4">Informações Básicas</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm text-zinc-400 block">Nome</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
                required
                placeholder="Seu nome completo"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm text-zinc-400 block">Idade</label>
              <input
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
                required
                min="18"
                max="100"
                placeholder="Sua idade"
              />
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-white mb-4">Experiência Profissional</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm text-zinc-400 block">Especialidade</label>
              <select
                name="specialty"
                value={formData.specialty}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
                required
              >
                <option value="">Selecione sua especialidade</option>
                {specialties.map((specialty) => (
                  <option key={specialty.value} value={specialty.value}>
                    {specialty.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="space-y-2">
              <label className="text-sm text-zinc-400 block">Tempo de Experiência</label>
              <div className="flex gap-4">
                <input
                  type="number"
                  name="experienceTime"
                  value={formData.experienceTime}
                  onChange={handleChange}
                  className="flex-1 px-1 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
                  required
                  min="0"
                  placeholder="Quantidade"
                />
                <select
                  name="experienceUnit"
                  value={formData.experienceUnit}
                  onChange={handleChange}
                  className="w-32 px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
                >
                  <option value="years">Anos</option>
                  <option value="months">Meses</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-white mb-4">Perfil e Contato</h2>
          <div className="space-y-2">
            <label className="text-sm text-zinc-400 block">GitHub</label>
            <input
              type="url"
              name="github"
              value={formData.github}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
              placeholder="https://github.com/seu-usuario"
              required
            />
          </div>
          <div className="space-y-2 mt-6">
            <label className="text-sm text-zinc-400 block">Descrição</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
              rows="4"
              placeholder="Conte um pouco sobre você, suas habilidades e experiências..."
              required
            />
          </div>
        </div>
      </div>

      <div className="flex-1">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-white">Projetos</h2>
          <button
            type="button"
            onClick={addProject}
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white text-sm"
          >
            <Plus className="w-4 h-4" />
            Adicionar Projeto
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {formData.projects.map((project, index) => (
            <div key={index} className="space-y-4 p-6 bg-zinc-800/30 rounded-xl border border-zinc-700/50">
              <div className="flex justify-between items-center">
                <h3 className="text-lg text-white font-medium">Projeto {index + 1}</h3>
                {formData.projects.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeProject(index)}
                    className="text-red-400 hover:text-red-300"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                )}
              </div>

              <div className="space-y-4">
                <input
                  type="text"
                  name="name"
                  value={project.name}
                  onChange={(e) => handleProjectChange(index, e)}
                  className="w-full px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
                  placeholder="Nome do Projeto"
                  required
                />
                <input
                  type="text"
                  name="role"
                  value={project.role}
                  onChange={(e) => handleProjectChange(index, e)}
                  className="w-full px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
                  placeholder="Seu Papel no Projeto"
                  required
                />
                <textarea
                  name="description"
                  value={project.description}
                  onChange={(e) => handleProjectChange(index, e)}
                  className="w-full px-4 py-3 rounded-xl bg-zinc-800/50 text-white border border-zinc-700"
                  rows="3"
                  placeholder="Descrição do Projeto"
                  required
                />
              </div>
            </div>
          ))}
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="w-full mt-6 py-3 rounded-lg bg-green-500 hover:bg-green-600 text-white text-lg font-semibold flex items-center justify-center"
        >
          <Check className="mr-2" /> Enviar Formulário
        </button>
      </div>
    </div>
    )}
    </div>
  );
};

export default TeamForm;

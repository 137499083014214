import React from 'react';
import { Eye, Users, FileUp, Diamond } from 'lucide-react';

const StatsSection = () => {
  const stats = [
    { icon: <Eye size={24} />, value: '45000+', label: 'Profile Views', percentage: '3% than last month' },
    { icon: <Users size={24} />, value: '2100+', label: 'Active Users', percentage: '5% than last month' },
    { icon: <FileUp size={24} />, value: '3900+', label: 'Files Uploaded', percentage: '2% than last month' },
    { icon: <Diamond size={24} />, value: '100+', label: 'Subscribers', percentage: '1% than last month' },
  ];

  return (
    <div className="bg-black text-white p-8 text-center mt-10">
      <h1 className="text-4xl font-extrabold mb-4">
        Join over <span className="text-[#542b55]">2100+</span> people on victims.lol — What are you waiting for?
      </h1>
      <p className="text-gray-400 mb-8">
        Create feature-rich, customizable, and modern bio pages, along with fast and secure file hosting, all with victims.lol.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 justify-items-center">
        {stats.map((stat, index) => (
          <div 
            key={index} 
            className="flex items-center justify-between bg-[#232323] bg-opacity-60 p-4 rounded-lg shadow-md w-full max-w-xs transition-transform duration-300 hover:scale-105"
          >
            <div className="rounded-xl p-2" style={{
              background: "linear-gradient(135deg, #000000, #542b55)",
            }}>
              {stat.icon} 
            </div>
            <div className="text-left ml-4">
              <h2 className="text-2xl font-extrabold text-white-800">{stat.value}</h2>
              <p className="text-gray-600">{stat.label}</p>
              <p className="text-green-600 text-sm">{stat.percentage}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsSection;

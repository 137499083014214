import '../css/JoinNow.css';
import { useNavigate } from 'react-router-dom';

const JoinNow = () => {

  const navigate = useNavigate();

  const handlePlansClick = () => {
    navigate('/pricing');
  };
  
  return (
    <div className="bg-black text-white py-10">
     <div className="community-card">
      <h2 className="text-3xl text-center">Everything you want, right here.</h2>
      <p>Join over 1000+ people using victims.lol and become part of our large community.</p>
      <button onClick={handlePlansClick} >Join Now</button>
     </div>
    </div>
  );
};

export default JoinNow;

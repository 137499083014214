import React, { useState, useEffect } from 'react';
import { Home, Users, Star, CreditCard, Pencil, Settings, MoreHorizontal, SwatchBook } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/Dashboard.css';
import axios from 'axios'

const ThemeCard = ({ theme, isActive, onPreview, onSelect }) => {
  return (
    <div className={`theme-card ${isActive ? 'active' : ''}`}>
      <p className="theme-name">{theme.name}</p>
      {theme.image ? (
        <img
          className="theme-card-image"
          src={theme.image}
          alt={theme.name ? `${theme.name} theme image` : 'Theme image'}
        />
      ) : (
        <div className="theme-card-image"></div>
      )}
      <div className="theme-card-buttons">
        <button className="theme-preview-btn" onClick={onPreview}>
          Preview
        </button>
        <button className={`theme-select-btn ${isActive ? 'selected' : ''}`} onClick={onSelect}>
          {isActive ? '✔' : 'Selecionar'}
        </button>
      </div>
    </div>
  );
};

const ThemesPage = () => {
  const { user, loading, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedTheme, setSelectedTheme] = useState('default');

  useEffect(() => {
    document.title = "victims.lol | themes";

    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';

    const existingLink = document.querySelector("link[rel*='icon']");
    if (existingLink) {
      document.head.removeChild(existingLink);
    }

    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  
  const themes = [
    { name: 'Default', value: 'default' },
    { name: 'Moana', value: 'moana', image: 'https://api-auth.victims.lol/api/proxy/image?url=https://cdn.victims.lol/uploads/moana-1727111939182.png' },
    { name: 'Cinnamoroll', value: 'cinnamoroll', image: 'https://api-auth.victims.lol/api/proxy/image?url=https://cdn.victims.lol/uploads/cb301c8c909ffa407603b4cac909a03d.png' },
    { name: 'Hollow Knight', value: 'hollow', image: 'https://api-auth.victims.lol/api/proxy/image?url=https://cdn.victims.lol/uploads/hollow-1727112769769-1730595369818.png' },
    { name: 'Stranger Things', value: 'strange', image: 'https://api-auth.victims.lol/api/proxy/image?url=https://cdn.victims.lol/uploads/8ff1051cca14d5c7a7f2f1e8c24fd55c-1722957956787-1730595404159.png'},
    { name: 'Hello Kitty', value: 'hello', image: 'https://api-auth.victims.lol/api/proxy/image?url=https://cdn.victims.lol/uploads/3zb4Fme-1728239596852-1730595276988.png'},
    { name: 'Kuromi', value: 'kuromi', image: 'https://api-auth.victims.lol/api/proxy/image?url=https://cdn.victims.lol/uploads/10726147-1730594144080.jpg'},
    { name: 'Pucca', value: 'pucca', image: 'https://api-auth.victims.lol/api/proxy/image?url=https://cdn.victims.lol/uploads/2672466-1728239876355-1730595644524.jpg'}
  ];

  const handlePreview = (theme) => {
    console.log(`Previewing theme: ${theme}`);
  };

  const handleSelect = async (theme) => {
    
    try {
      const response = await fetch('https://api-auth.victims.lol/api/save/theme', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pageTheme: theme, user }),
      });

      if (response.ok) {
        toast.success('Theme saved successfully!');
        fetchUser(); 
        setSelectedTheme(theme);
      } else {
        toast.error('Failed to save theme page.');
      }
    } catch (error) {
      toast.error('An error occurred.');
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  const handleCustomizeClick = () => {
    navigate('/dashboard/customize');
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleSocialsClick = () => {
    navigate('/dashboard/socials');
  };

  const handleUsersClick = () => {
    navigate('/dashboard/users')
  };

  const handlePlansClick = () => {
    navigate('/pricing');
  };

  const handleAdminClick = () => {
    navigate('/dashboard/admin');
  };

  const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";
    
    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar 
          ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar 
        ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
        : defaultAvatar;
    }
};


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    navigate('/logout')
    fetchUser()
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get('https://api-auth.victims.lol/api/me', { withCredentials: true });
      setUser(response.data);
    } catch (error) {
      console.log(error);
    } 
  };

  useEffect(() => {
    if (user && user.data.page_theme) {
      setSelectedTheme(user.data.page_theme); 
    }
  }, [user]);
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen text-white" style={{ background: "#000000" }}>
      <button
        className="block md:hidden fixed top-4 left-4 bg-gray-800 p-2 rounded"
        style={{ width: "40px" }}
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? 'X' : '☰'}
      </button>

      <div
        className={`w-64 bg-opacity-80 backdrop-filter backdrop-blur-lg p-4 flex flex-col overflow-auto fixed md:static transform transition-transform duration-300 z-30 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 h-full`}
        style={{ borderRadius: "0 20px 20px 0", background: "rgba(23, 23, 23, 0.8)" }}
      >
        <div className="mb-8 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={getAvatarUrl(user)}
              alt="User"
              className="w-12 h-12 rounded-full mr-3"
            />
            <div>
              <h2 className="text-sm font-bold text-gray-300" style={{ fontSize: '17px' }}>
                Welcome, {user.discord.globalName || user.discord.username}
              </h2>
              <p className="text-sm text-gray-400">
                {user.data.page_url ? `victims.lol/${user.data.page_url}` : 'Não definido.'}
              </p>
            </div>
          </div>
          <button className="text-gray-300 hover:text-gray-500 transition-all md:hidden" onClick={toggleSidebar}>
            X
          </button>
        </div>
        <hr className="mb-6 transparent-hr" />
        <nav className="flex-grow">
          <SidebarLink icon={<Home />} label="Dashboard" onClick={handleDashboardClick} />
          <SidebarLink icon={<Pencil />} label="Customize" onClick={handleCustomizeClick} />
          <SidebarLink icon={<Star />} label="Social Links" onClick={handleSocialsClick}  />
          <SidebarLink icon={<Users />} label="Users" onClick={handleUsersClick} />
          <SidebarLink icon={<SwatchBook />} label="Themes" active />
          <SidebarLink icon={<CreditCard />} label="Plans" onClick={handlePlansClick} />       
          {(user.data.team_role === "owner" || user.data.team_role === "staff") && (
           <SidebarLink icon={<Settings />} label="Admin" onClick={handleAdminClick} />
          )}
        </nav>
        <div className="mt-auto">
          <div className="flex items-center justify-between p-3" style={{
            background: "rgb(20,20,20)",
            borderRadius: "40px"
          }}>
            <div className="flex items-center">
              <img
                src={getAvatarUrl(user)}
                alt="User"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <h3 className="text-sm font-semibold">{user.discord.globalName || user.discord.username}</h3>
              </div>
            </div>
            <div className="relative">
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-300 hover:text-white focus:outline-none"
              >
                <MoreHorizontal size={35} style={{
                  marginTop: "4px"
                }} />
              </button>
              {isMenuOpen && (
                <div className="absolute right-0 bottom-full mb-2 w-48 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Home</a>
                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Logout</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 p-8 overflow-auto">
        <div className="mb-6">
          <div className="themes-page">
            <h2 className="text-2xl font-bold mb-4">Configurações de Temas</h2>
            <h3 className="text-xl mb-4">Lista de temas</h3>
            <div className="themes-list">
              {themes.map((theme) => (
                <ThemeCard
                  key={theme.value}
                  theme={theme}
                  isActive={selectedTheme === theme.value} 
                  onPreview={() => handlePreview(theme.value)}
                  onSelect={() => handleSelect(theme.value)}
                />
              ))}
            </div>
          </div>
        </div>
      <ToastContainer />
      </div>
    </div>
  );
};

const SidebarLink = ({ icon, label, onClick, active }) => (
  <div className={`flex items-center p-3 rounded-lg mb-2 ${active ? 'violet bg-opacity-50' : 'hover:bg-white hover:bg-opacity-30'} transition-all transform hover:scale-105`} onClick={onClick}>
    {React.cloneElement(icon, { size: 20, className: 'text-gray-300' })}
    <span className="ml-3 font-medium">{label}</span>
  </div>
);

export default ThemesPage;
import React, { useState, useEffect } from 'react';
import { ArrowRight, User, Settings, Copy, LogOut } from 'lucide-react';
import { useAuth } from '../hooks/useAuth'; 
import { useNavigate } from 'react-router-dom';
import FAQ from '../components/Faq'
import JoinNow from '../components/JoinNow'
import Footer from '../components/Footer'
import TopUsers from '../components/TopUsers'
import FeaturesSection from '../components/FeaturesSection'
import StatsSection from '../components/StatsSection'

const HomePage = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { user } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "victims.lol | home";
    
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png';
        link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';
    
        const existingLink = document.querySelector("link[rel*='icon']");
        if (existingLink) {
          document.head.removeChild(existingLink);
        }
    
        document.head.appendChild(link);
        
        return () => {
          document.head.removeChild(link);
        };
      }, []);

    const handleDropdownToggle = () => {
        setDropdownOpen(prevState => !prevState);
    };

    const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";
    
    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar 
          ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar 
        ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
        : defaultAvatar;
    }
};


    return (
      <div className="bg-black min-h-screen text-white font-sans overflow-hidden relative">
        <header className="relative z-10 p-6 flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <ArrowRight className="h-8 w-8 text-white" />
                    <span className="font-bold text-2xl text-white">Victims.lol</span>
                </div>

                <nav className="hidden md:flex space-x-6 items-center">
                    <a href="/team" className="text-sm hover:text-gray-300 transition-colors">Team</a>
                    <a href="/discord" className="text-sm hover:text-gray-300 transition-colors">Discord</a>
                    <a href="/pricing" className="text-sm hover:text-gray-300 transition-colors">Pricing</a>
                    {user ? (
                        <div className="relative">
                            <button onClick={handleDropdownToggle} className="flex items-center space-x-2">
                                <img src={getAvatarUrl(user)} alt="Avatar" className="w-8 h-8 rounded-full" />
                                <span>{user.discord.username}</span>
                            </button>
                            {dropdownOpen && (
                                <div className="absolute right-0 mt-2 bg-[#000000B0] text-white border border-gray-700 rounded-lg shadow-lg z-20 w-48">
                                    <div className="p-2 border-b border-gray-700">
                                        <p className="text-sm text-gray-400">{user.discord.username}</p>
                                    </div>
                                    <a href="/profile" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <User className="mr-2 h-4 w-4" />
                                        Your Profile
                                    </a>
                                    <a href="/dashboard" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <Settings className="mr-2 h-4 w-4" />
                                        Dashboard
                                    </a>
                                    <a href="/copy-profile-url" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <Copy className="mr-2 h-4 w-4" />
                                        Copy your page
                                    </a>
                                    <a href="/logout" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800 text-red-500">
                                        <LogOut className="mr-2 h-4 w-4" />
                                        Logout
                                    </a>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <a href="/login" className="text-sm hover:text-gray-300 transition-colors">Login</a>
                            <button className="bg-white text-black text-sm px-6 py-3 rounded-full hover:bg-gray-200 transition-all transform hover:scale-105 font-semibold">
                                Sign Up Free
                            </button>
                        </>
                    )}
                </nav>

                <button className="md:hidden text-white" onClick={() => setMenuOpen(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 5h14a1 1 0 110 2H3a1 1 0 110-2zm0 4h14a1 1 0 110 2H3a1 1 0 110-2zm0 4h14a1 1 0 110 2H3a1 1 0 110-2z" clipRule="evenodd" />
                    </svg>
                </button>
        </header>

        {menuOpen && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-90 p-6 md:hidden z-20 flex flex-col space-y-6 items-start">
                    <button className="self-end text-white text-6xl" onClick={() => setMenuOpen(false)}>
                        &times;
                    </button>

                    <nav className="flex flex-col space-y-6 w-full mb-6 text-center">
                        <a href="/team" className="text-lg hover:text-gray-300 transition-colors">Team</a>
                        <a href="/discord" className="text-lg hover:text-gray-300 transition-colors">Discord</a>
                        <a href="/pricing" className="text-lg hover:text-gray-300 transition-colors">Pricing</a>
                    </nav>

                    <div className="flex flex-col space-y-4 w-full">
                        {user ? (
                            <div className="relative flex flex-col items-center space-y-4">
                               <button onClick={handleDropdownToggle} className="flex items-center space-x-2">
                                <img src={getAvatarUrl(user)} alt="Avatar" className="w-11 h-11 rounded-full" />
                                <span style={{
                                    fontSize: '1.3rem',
                                }}>{user.discord.username}</span>
                              </button>
                              {dropdownOpen && (
                                <div className="absolute right-0 mt-2 bg-[#000000B0] text-white border border-gray-700 rounded-lg shadow-lg z-20 w-48">
                                    <div className="p-2 border-b border-gray-700">
                                        <p className="text-sm text-gray-400">{user.discord.username}</p>
                                    </div>
                                    <a href="/profile" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <User className="mr-2 h-4 w-4" />
                                        Your Profile
                                    </a>
                                    <a href="/dashboard" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <Settings className="mr-2 h-4 w-4" />
                                        Dashboard
                                    </a>
                                    <a href="/copy-profile-url" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800">
                                        <Copy className="mr-2 h-4 w-4" />
                                        Copy your page
                                    </a>
                                    <a href="/logout" className="flex items-center px-4 py-2 text-sm hover:bg-gray-800 text-red-500">
                                        <LogOut className="mr-2 h-4 w-4" />
                                        Logout
                                    </a>
                                </div>
                            )}
                            </div>
                        ) : (
                            <>
                                <button className="bg-white text-black text-lg px-6 py-3 rounded-full hover:bg-gray-200 transition-all transform hover:scale-105 font-semibold w-full">
                                    Sign Up Free
                                </button>
                                <a href="/login" className="text-lg bg-transparent border border-white text-white py-2 px-6 rounded-full text-center hover:bg-white hover:text-black transition-colors w-full">
                                    Login
                                </a>
                            </>
                        )}
                    </div>
                </div>
        )}

        <div className="text-white p-8 mt-12">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <div className="flex items-center mb-4">
            <div className="flex -space-x-2 mr-4">
              <div className="w-8 h-8 rounded-full bg-purple-400">
                <img src="https://api.victims.lol/api/avatar/551361626461306912" className='rounded-full' alt='' />
              </div>
              <div className="w-8 h-8 rounded-full bg-blue-400">
               <img src="https://api.victims.lol/api/avatar/1116100803204223097" className='rounded-full' alt='' />
              </div>
              <div className="w-8 h-8 rounded-full bg-green-400">
               <img src="https://api.victims.lol/api/avatar/1096963917902782535" className='rounded-full' alt='' />
              </div>
            </div>
            <span className="text-sm">2100+ Users</span>
          </div>
          <h1 className="text-4xl md:text-5xl font-bold mb-4">Create super page for you and your friends</h1>
          <p className="mb-6 text-gray-300">
            victims.lol is a platform that offers services for creating personalized bio pages for you and your Discord friends. Want to showcase your info in a unique and creative space? Don't wait any longer! Join now and be part of the first platform with this purpose, offering a new and exciting experience.
          </p>
          <div className="flex space-x-4">
            <button onClick={() => navigate(`/login`)} className="text-white py-2 px-6 rounded-full" style={{
              background: "rgb(84,43,85)"
            }}>
              Explore Now
            </button>
            <button className="relative flex items-center gap-2 p-4 px-8 rounded-full bg-transparent transform transition-transform ease-in-out duration-300 hover:scale-105 active:scale-100 border-none" onClick={() => navigate(`/login`)}>
            <span className="absolute top-1/2 left-1/2 w-full h-full bg-[hsla(0,0%,12%,1)] rounded-full transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 z-0" />
             <span className="relative z-10 text-transparent bg-clip-text bg-gradient-to-r from-white to-transparent via-white text-lg">
             Get start free
            </span>
           </button>
          </div>
        </div>
        <div className="md:w-1/2 relative">
          <div className="md:w-80 md:h-90 relative overflow-hidden w-full max-w-xs lg:max-w-sm rounded-lg image-effect border-animation md:ml-0 ml-60" style={{
            marginLeft: window.innerWidth < 768 ? "0px" : "100px",
          }}>
             <img src="https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/image-1728346658335.png" 
             alt="" 
             />
             </div>
        </div>
           </div>
        </div>

        <FeaturesSection />
        <TopUsers />
        <StatsSection />
        <FAQ />
        <JoinNow />
        <Footer />
      </div>
        
    );
};

export default HomePage;
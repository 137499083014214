import React, { useState, useEffect } from 'react';
import { Home, Users, Star, CreditCard, Pencil, Settings, MoreHorizontal, SwatchBook, LockKeyhole, Upload } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Input = ({ label, placeholder, type, value, onChange }) => (
  <div className="flex flex-col mb-4">
    <label className="mb-2" style={{ color: "#C4C4C4" }}>{label}</label>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="text-white rounded-md p-2 focus:outline-none focus:ring-1 focus:ring-gray-600"
      style={{ background: "#121212", border: "2px solid #151515" }}
    />
  </div>
);

const Select = ({ label, children, value, onChange }) => (
  <div className="flex flex-col mb-4">
    <label className="text-gray-400 mb-2">{label}</label>
    <select
      className="text-white rounded-md p-2 focus:outline-none focus:ring-1 focus:ring-gray-600"
      value={value}
      onChange={onChange}
      style={{ background: "#121212", border: "2px solid #151515" }}>
      {children}
    </select>
  </div>
);

const Dashboard = () => {
  const { user, loading } = useAuth();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [userData, setUserData] = useState(null);
  const [pageName, setPageName] = useState('');
  const [pageUrlPage, setUrlPage] = useState('');
  const [pageButtonPage, setButtonPage] = useState('');
  const [pageLayout, setPageLayout] = useState('');
  const [pageEffects, setPageEffects] = useState('');
  const [suspenseProfile, setSuspense] = useState('');
  const [currentPremium, setPremium] = useState('');

  useEffect(() => {
    document.title = "victims.lol | admin";

    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';

    const existingLink = document.querySelector("link[rel*='icon']");
    if (existingLink) {
      document.head.removeChild(existingLink);
    }

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      if (!user || (user.data.team_role !== "owner" && user.data.team_role !== "staff")) {
        setIsAuthorized(false);
      }
    }
  }, [user, loading]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/user-admin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        console.log(data);
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchUserProfile();
    }
  }, [id]);

  const handleSaveChanges = async () => {

    console.log("recebi")

    if (pageName.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/name', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageName, user: userData }),
        });

        if (response.ok) {
          toast.success('Page name saved successfully!');
          setPageName('');
        } else {
          toast.error('Failed to save page name.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }

    if (pageUrlPage.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/url', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageUrlPage, user: userData }),
        });

        if (response.ok) {
          toast.success('Page URL saved successfully!');
          setUrlPage('');
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.message || 'Page URL is already in use.');
        } else {
          toast.error('Failed to save page URL.');
        }
      } catch (error) {
        toast.error('An error occurred while saving the page URL.');
      }
    }

    if (pageButtonPage.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/button', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageButtonPage, user: userData }),
        });

        if (response.ok) {
          toast.success('Button saved successfully!');
          setButtonPage('');
        } else {
          toast.error('Failed to save button link.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }

    if (pageLayout.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/layout', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageLayout, user: userData }),
        });

        if (response.ok) {
          toast.success('Layout saved successfully!');
        } else {
          toast.error('Failed to save Layout page.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }

    if (pageEffects.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/effects', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageEffects, user: userData }),
        });

        if (response.ok) {
          toast.success('Effect saved successfully!');
        } else {
          toast.error('Failed to save Effect page.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      }
    }

    if (suspenseProfile.trim()) {
      try {
        const response = await fetch('https://api-auth.victims.lol/api/save/suspense', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ suspenseProfile, user: userData }),
        });

         if (response.ok) {
          toast.success('Suspense profile successfully!');
        } else {
          toast.error('Failed to suspense Profile.');
        }
      } catch (error) {
          toast.error('Failed to request API.')
      }
    }
  };

  const handleCustomizeClick = () => {
    navigate('/dashboard/customize');
  };

  const handleUsersClick = () => {
    navigate('/dashboard/users');
  };

  const handleThemeClick = () => {
    navigate('/dashboard/themes');
  };

  const handlePlansClick = () => {
    navigate('/pricing');
  };

  const handleAdminClick = () => {
    navigate('/dashboard');
  };

  const handleSocialsClick = () => {
    navigate('/dashboard/socials');
  };

  const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";

    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar
          ? `https://api.victims.lol/api/avatar/${user.discord.id}`
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar
        ? `https://api.victims.lol/api/avatar/${user.discord.id}`
        : defaultAvatar;
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    navigate('/logout')
  };

  if (isLoading || !user || !userData || !userData.discord.displayAvatarURL) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p>Loading...</p>
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black text-white p-6">
        <div className="bg-gray-900 p-8 rounded-lg shadow-xl max-w-lg w-full text-center">
          <h2 className="text-3xl font-bold mb-4">Acesso Negado</h2>
          <p className="text-lg mb-6">Esta página é privada e o acesso é concedido apenas para membros da equipe.</p>
          <div className="flex justify-center gap-4">
            <Link
              to="/home"
              className="bg-purple-600 text-white px-6 py-2 rounded-lg text-lg hover:bg-purple-700 transition duration-300"
            >
              Voltar ao Início
            </Link>
            <Link
              to="/dashboard"
              className="bg-purple-600 text-white px-6 py-2 rounded-lg text-lg hover:bg-purple-700 transition duration-300"
            >
              Ir para a Dashboard
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen text-white" style={{ background: "#000000" }}>
      <ToastContainer />
      <button
        className="block md:hidden fixed top-4 left-4 bg-gray-800 p-2 rounded"
        style={{ width: "40px" }}
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? 'X' : '☰'}
      </button>

      <div
        className={`w-64 bg-opacity-80 backdrop-filter backdrop-blur-lg p-4 flex flex-col overflow-auto fixed md:static transform transition-transform duration-300 z-30 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 h-full`}
        style={{ borderRadius: "0 20px 20px 0", background: "rgba(23, 23, 23, 0.8)" }}
      >
        <div className="mb-8 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={getAvatarUrl(user)}
              alt="User"
              className="w-12 h-12 rounded-full mr-3"
            />
            <div>
              <h2 className="text-sm font-bold text-gray-300" style={{ fontSize: '17px' }}>
                Welcome, {user.discord.globalName || user.discord.username}
              </h2>
              <p className="text-sm text-gray-400">
                {user.data.page_url ? `victims.lol/${user.data.page_url}` : 'Não definido.'}
              </p>
            </div>
          </div>
          <button className="text-gray-300 hover:text-gray-500 transition-all md:hidden" onClick={toggleSidebar}>
            X
          </button>
        </div>
        <hr className="mb-6 transparent-hr" />
        <nav className="flex-grow">
          <SidebarLink icon={<Home />} label="Dashboard" onClick={handleAdminClick} />
          <SidebarLink icon={<Pencil />} label="Customize" onClick={handleCustomizeClick} />
          <SidebarLink icon={<Star />} label="Social Links" onClick={handleSocialsClick} />
          <SidebarLink icon={<Users />} label="Users" onClick={handleUsersClick} />
          <SidebarLink icon={<SwatchBook />} label="Themes" onClick={handleThemeClick} />
          <SidebarLink icon={<CreditCard />} label="Plans" onClick={handlePlansClick} />
          {(user.data.team_role === "owner" || user.data.team_role === "staff") && (
            <SidebarLink icon={<Settings />} label="Admin" Active onClick={() => navigate('/dashboard/admin')} />
          )}
        </nav>
        <div className="mt-auto">
          <div className="flex items-center justify-between p-3" style={{
            background: "rgb(20,20,20)",
            borderRadius: "40px"
          }}>
            <div className="flex items-center">
              <img
                src={getAvatarUrl(user)}
                alt="User"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <h3 className="text-sm font-semibold">{user.discord.globalName || user.discord.username}</h3>
              </div>
            </div>
            <div className="relative">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-300 hover:text-white focus:outline-none"
              >
                <MoreHorizontal size={35} style={{
                  marginTop: "4px"
                }} />
              </button>
              {isMenuOpen && (
                <div className="absolute right-0 bottom-full mb-2 w-48 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Home</a>
                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Logout</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow p-8 overflow-y-auto rounded-lg shadow-lg">
        <div className="bg-black rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <div className="w-20 h-20 overflow-hidden mr-6">
              <img src={userData?.discord?.displayAvatarURL ? userData?.discord?.displayAvatarURL : "https://api.victims.lol/api/avatar/1000141931621388308"} alt="Profile" className="w-full h-full object-cover shadow-lg  rounded-full" />
            </div>
            <div>
              <h2 className="text-white font-extrabold text-2xl">{userData.discord.globalName || userData.discord.username}</h2>
              <p className="text-gray-300">@{userData.discord.username}</p>
              <p className="text-gray-400 mt-2">Views: {userData.data?.views || 0}</p>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">

            <Input label="Page Name" placeholder={userData.data.page_name || "Not defined"} value={pageName} onChange={(e) => setPageName(e.target.value)} />
            <Input label="Page URL" placeholder={userData.data.page_url || "Not defined"} value={pageUrlPage} onChange={(e) => setUrlPage(e.target.value)} />
            <Input label="Button Link" placeholder={userData.data.page_button || "Not defined"} value={pageButtonPage} onChange={(e) => setButtonPage(e.target.value)} />

            <Select label="Select Layout" value={pageLayout} onChange={(e) => setPageLayout(e.target.value)} >
              <optgroup label="Layouts">
                {(!userData.data.profile_layout || userData.data.profile_layout === "1") && (
                  <>
                    <option value="1">Layout 1</option>
                    <option value="2">Layout 2</option>
                    <option value="3">Layout 3</option>
                  </>
                )}
                {(userData.data.profile_layout === "2") && (
                  <>
                    <option value="2">Layout 2</option>
                    <option value="1">Layout 1</option>
                    <option value="3">Layout 3</option>
                  </>
                )}

                {(userData.data.profile_layout === "3") && (
                  <>
                    <option value="3">Layout 3</option>
                    <option value="2">Layout 2</option>
                    <option value="1">Layout 1</option>
                  </>
                )}
              </optgroup>
            </Select>

            <Select label="Page Effects" value={pageEffects} onChange={(e) => setPageEffects(e.target.value)}>
              <optgroup label="Page Effects">
                {(userData.data.partials === "none" || !userData.data.partials) && (
                  <>
                    <option value="none">No Effect</option>
                    <option value="moon">Moon</option>
                    <option value="stars">Stars</option>
                    <option value="kuromi">Kuromi</option>
                    <option value="hello-kitty">Hello Kitty</option>
                    <option value="snowflakes">SnowFlakes</option>
                  </>
                )}
                {userData.data.partials === "moon" && (
                  <>
                    <option value="moon">Moon</option>
                    <option value="none">No Effect</option>
                    <option value="stars">Stars</option>
                    <option value="kuromi">Kuromi</option>
                    <option value="hello-kitty">Hello Kitty</option>
                    <option value="snowflakes">SnowFlakes</option>
                  </>
                )}
                {userData.data.partials === "stars" && (
                  <>
                    <option value="stars">Stars</option>
                    <option value="moon">Moon</option>
                    <option value="none">No Effect</option>
                    <option value="kuromi">Kuromi</option>
                    <option value="hello-kitty">Hello Kitty</option>
                    <option value="snowflakes">SnowFlakes</option>
                  </>
                )}
                {userData.data.partials === "kuromi" && (
                  <>
                    <option value="kuromi">Kuromi</option>
                    <option value="none">No Effect</option>
                    <option value="moon">Moon</option>
                    <option value="stars">Stars</option>
                    <option value="hello-kitty">Hello Kitty</option>
                    <option value="snowflakes">SnowFlakes</option>
                  </>
                )}
                {userData.data.partials === "hello-kitty" && (
                  <>
                    <option value="hello-kitty">Hello Kitty</option>
                    <option value="none">No Effect</option>
                    <option value="moon">Moon</option>
                    <option value="stars">Stars</option>
                    <option value="kuromi">Kuromi</option>
                    <option value="snowflakes">SnowFlakes</option>
                  </>
                )}
                {userData.data.partials === "snowflakes" && (
                  <>
                    <option value="snowflakes">SnowFlakes</option>
                    <option value="none">No Effect</option>
                    <option value="moon">Moon</option>
                    <option value="stars">Stars</option>
                    <option value="kuromi">Kuromi</option>
                    <option value="hello-kitty">Hello Kitty</option>
                  </>
                )}
              </optgroup>
            </Select>

            <Select label="Suspense" value={suspenseProfile} onChange={(e) => setSuspense(e.target.value)}>
              <optgroup label="Suspense">
                {(!userData.data.suspense || userData.data.suspense === "false") && (
                  <>
                    <option value="false">False</option>
                    <option value="true">True</option>
                  </>
                )}
                {userData.data.suspense === "true" && (
                  <>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </>
                )}
              </optgroup>
            </Select>

            <Select label="Current Premium" value={currentPremium} onChange={(e) => setPremium(e.target.value)}>
              <optgroup label="Current Premium">
                <option value="none">No Premium</option>
                <option value="gold">Moon</option>
                <option value="stars">Stars</option>
              </optgroup>
            </Select>
          </div>

          <div className="p-6 rounded-lg mb-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 place-items-center mt-4">

              {(userData.data.current_premium === "gold" || userData.data.current_premium === "diamond") ? (
                <AssetUploader
                  title="Background"
                  url="https://api-auth.victims.lol/api/save/background"
                  user={userData}

                  assetType="page_background"
                  accept="image/jpeg, image/png, image/gif"
                />
              ) : (
                <AssetUploaderLock />
              )}
              {(userData.data.current_premium === "gold" || userData.data.current_premium === "diamond") ? (
                <AssetUploader
                  title="Custom Cursor"
                  url="https://api-auth.victims.lol/api/save/curso"
                  user={userData}

                  assetType="page_curso"
                  accept="image/jpeg, image/png"
                />
              ) : (
                <AssetUploaderLock />
              )}
              {(userData.data.current_premium === "gold" || userData.data.current_premium === "diamond") ? (
                <AssetUploader
                  title="Profile Avatar"
                  url="https://api-auth.victims.lol/api/save/avatar"
                  user={userData}
                  fetchUser={userData}
                  assetType="user_avatar"
                  accept="image/jpeg, image/png, image/gif"
                />
              ) : (
                <AssetUploaderLock />
              )}
              <AssetUploader
                title="Audio"
                url="https://api-auth.victims.lol/api/save/audio"
                user={userData}
                assetType="page_song"
                accept="audio/mpeg"
              />
              {(userData.data.current_premium === "gold" || userData.data.current_premium === "diamond") ? (
                <AssetUploader
                  title="Favicon"
                  url="https://api-auth.victims.lol/api/save/favicon"
                  user={userData}
                  assetType="page_favicon"
                  accept="image/jpeg, image/png"
                />
              ) : (
                <AssetUploaderLock />
              )}
            </div>
          </div>

          <button onClick={handleSaveChanges} className="rounded-md py-2 px-6 text-white font-semibold hover:bg-purple-500 transition-colors duration-300 w-full mt-6" style={{
            backgroundColor: '#171717',
          }}>
            Apply
          </button>
        </div>
      </div>

    </div>
  );
};


const AssetUploader = ({ title, url, user, assetType, accept }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(user.data[assetType] || '');

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (imageUrl) {
        alert('Já existe uma imagem. Remova a imagem atual antes de fazer um novo upload.');
        return;
      }

      setUploading(true);
      setError(null);

      if (assetType === 'page_background' && !['image/png', 'image/jpeg', 'image/gif'].includes(selectedFile.type)) {
        setError('Apenas imagens e GIFs são permitidos para o background.');
        toast.error('Apenas imagens e GIFs são permitidos para o background.');
        return;
      }
      if (assetType === 'user_avatar' && !['image/png', 'image/jpeg', 'image/gif'].includes(selectedFile.type)) {
        setError('Apenas imagens e GIFs são permitidos para o avatar.');
        toast.error('Apenas imagens e GIFs são permitidos para o avatar.');
        return;
      }
      if (assetType === 'page_favicon' && !['image/png', 'image/jpeg'].includes(selectedFile.type)) {
        setError('Apenas imagens são permitidas para o favicon.');
        toast.error('Apenas imagens são permitidas para o favicon.');
        return;
      }
      if (assetType === 'page_curso' && !['image/png', 'image/jpeg'].includes(selectedFile.type)) {
        setError('Apenas imagens são permitidas para o cursor.');
        toast.error('Apenas imagens são permitidas para o cursor.');
        return;
      }

      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const cdnResponse = await fetch('https://cdn.victims.lol/upload', {
          method: 'POST',
          body: formData,
        });

        if (!cdnResponse.ok) {
          throw new Error('Falha ao fazer upload para o CDN.');
        }

        const cdnData = await cdnResponse.json();
        const uploadedUrl = cdnData.url;

        const serverResponse = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            url: uploadedUrl,
            user: user
          }),
        });

        if (!serverResponse.ok) {
          throw new Error(`Falha ao salvar ${title} no servidor.`);
        }

        setUploading(false);
        setImageUrl(uploadedUrl);
        toast.success(title + ' enviado com sucesso!');
      } catch (error) {
        setUploading(false);
        setError(error.message);
      }
    }
  };

  const handleRemoveImage = async (event) => {
    try {
      const serverResponse = await fetch(`${url}/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: user
        }),
      });

      if (!serverResponse.ok) {
        toast.error('Falha ao remover a imagem.');
      }

      setImageUrl('');
      toast.success(title + ' removido com sucesso!');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleContainerClick = () => {
    const fileInput = document.getElementById(`file-input-${assetType}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error(`Element with id file-input-${assetType} not found.`);
    }
  };

  const getImageUrl = (url) => {
    return `https://api-auth.victims.lol/api/proxy?url=${url}`;
  };

  const imageUrlToDisplay = assetType === "page_song"
    ? 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/icons8-song-96-1728227167548.png'
    : getImageUrl(imageUrl);

  const styleDisplay = assetType === "page_song" ? {
    width: '55%',
    height: '65%',
    objectFit: 'cover',
  } : {
    width: '100%',
    borderRadius: "5px",
    height: '100%',
    objectFit: 'cover',
  };

  return (
    <div
      className="flex flex-col items-center p-4 rounded-lg relative"
      style={{
        border: "2px solid #181818",
        width: '200px',
        height: '180px',
        overflow: 'hidden',
        cursor: 'pointer',
        justifyContent: 'center',
      }}
      onClick={handleContainerClick}
    >
      {imageUrl ? (
        <div className="relative w-full h-full flex items-center justify-center">
          <img
            src={imageUrlToDisplay}
            alt={title}
            style={styleDisplay}
          />
          <button
            onClick={handleRemoveImage}
            className="absolute top-2 right-2 text-red-500 bg-black bg-opacity-70 rounded-lg p-1 w-8 h-8 flex items-center justify-center"
            title="Remove Image"
          >
            X
          </button>
        </div>
      ) : (
        <>
          <Upload className="text-gray-400 mb-2" size={32} />
          <h3 className="text-white mb-2 text-center">{title}</h3>
          <input
            id={`file-input-${assetType}`}
            type="file"
            onChange={handleFileChange}
            accept={accept}
            className="hidden"
          />
          {uploading && <p className="text-yellow-500">Fazendo upload...</p>}
          {error && <p className="text-red-500">{error}</p>}
        </>
      )}
    </div>
  );
};

const AssetUploaderLock = () => {

  return (
    <div
      className="flex flex-col items-center p-4 rounded-lg relative"
      style={{
        backgroundColor: 'rgb(15,15,15)',
        backdropFilter: 'blur(8px)',
        border: "2px solid #181818",
        width: '200px',
        height: '180px',
        overflow: 'hidden',
        cursor: 'not-allowed',
        justifyContent: 'center'
      }}
    >

      <LockKeyhole className="text-gray-400 mb-2" size={32} />
    </div>
  );
};


const SidebarLink = ({ icon, label, onClick, active }) => (
  <div className={`flex items-center p-3 rounded-lg mb-2 ${active ? 'violet bg-opacity-50' : 'hover:bg-white hover:bg-opacity-30'} transition-all transform hover:scale-105`} onClick={onClick}>
    {React.cloneElement(icon, { size: 20, className: 'text-gray-300' })}
    <span className="ml-3 font-medium">{label}</span>
  </div>
);

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { Home, Users, Star, CreditCard, Pencil, Settings, MoreHorizontal, SwatchBook } from 'lucide-react';
import { FaLink, FaPinterestP, FaLastfm, FaKickstarterK, FaInstagram, FaTwitter, FaGithub, FaTiktok, FaSpotify, FaTwitch, FaLinkedin, FaGitlab } from 'react-icons/fa';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/Dashboard.css';
import axios from 'axios'

const Socials = () => {
  const { user, loading, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [links, setLinks] = useState({
    instagram: '',
    twitter: '',
    github: '',
    tiktok: '',
    spotify: '',
    twitch: '',
    pinterest: '',
    linkedin: '',
    gitlab: '',
    lastfm: '',
    kick: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "victims.lol | socials";

    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';

    const existingLink = document.querySelector("link[rel*='icon']");
    if (existingLink) {
      document.head.removeChild(existingLink);
    }

    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  const handleCustomizeClick = () => {
    navigate('/dashboard/customize');
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleUsersClick = () => {
    navigate('/dashboard/users');
  };

  const handleThemeClick = () => {
    navigate('/dashboard/themes');
  };

  const handlePlansClick = () => {
    navigate('/pricing');
  };

  const handleAdminClick = () => {
    navigate('/dashboard/admin');
  };

  const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";
    
    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar 
          ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar 
        ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
        : defaultAvatar;
    }
};


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    navigate('/logout')
    fetchUser()
  };

  const handleLinkChange = (platform, value) => {
    setLinks(prevLinks => ({ ...prevLinks, [platform]: value }));
  };

  const handleSave = async () => {
    try {
      const filteredLinks = Object.fromEntries(
        Object.entries(links).filter(([key, value]) => value)
      );
  
      if (Object.keys(filteredLinks).length === 0) {
        toast.warning('No links to save.');
        return;
      }
  
      const response = await fetch('https://api-auth.victims.lol/api/save/socials', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user, socials: filteredLinks }), 
      });
  
      if (!response.ok) {
        throw new Error('Failed to save links');
      }
  
      const data = await response.json();
      console.log('Links saved:', data);
      toast.success('Links saved successfully!');
  
      await fetchUser(); 
  
      setLinks({
        instagram: '',
        twitter: '',
        github: '',
        tiktok: '',
        spotify: '',
        twitch: '',
        pinterest: '',
        linkedin: '',
        gitlab: '',
        lastfm: '',
        kick: ''
      });
  
    } catch (error) {
      console.error('Error saving links:', error);
      toast.error('Failed to save links.');
    }
  };  

  const fetchUser = async () => {
    try {
      const response = await axios.get('https://api-auth.victims.lol/api/me', { withCredentials: true });
      setUser(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen text-white" style={{ background: "#000000" }}>
      <button
        className="block md:hidden fixed top-4 left-4 bg-gray-800 p-2 rounded"
        style={{ width: "40px" }}
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? 'X' : '☰'}
      </button>

      <div
        className={`w-64 bg-opacity-80 backdrop-filter backdrop-blur-lg p-4 flex flex-col overflow-auto fixed md:static transform transition-transform duration-300 z-30 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 h-full`}
        style={{ borderRadius: "0 20px 20px 0", background: "rgba(23, 23, 23, 0.8)" }}
      >
        <div className="mb-8 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={getAvatarUrl(user)}
              alt="User"
              className="w-12 h-12 rounded-full mr-3"
            />
            <div>
              <h2 className="text-sm font-bold text-gray-300" style={{ fontSize: '17px' }}>
                Welcome, {user.discord.globalName || user.discord.username}
              </h2>
              <p className="text-sm text-gray-400">
                {user.data.page_url ? `victims.lol/${user.data.page_url}` : 'Não definido.'}
              </p>
            </div>
          </div>
          <button className="text-gray-300 hover:text-gray-500 transition-all md:hidden" onClick={toggleSidebar}>
            X
          </button>
        </div>
        <hr className="mb-6 transparent-hr" />
        <nav className="flex-grow">
          <SidebarLink icon={<Home />} label="Dashboard" onClick={handleDashboardClick} />
          <SidebarLink icon={<Pencil />} label="Customize" onClick={handleCustomizeClick} />
          <SidebarLink icon={<Star />} label="Social Links" active />
          <SidebarLink icon={<Users />} label="Users" onClick={handleUsersClick} />
          <SidebarLink icon={<SwatchBook />} label="Themes" onClick={handleThemeClick} />
          <SidebarLink icon={<CreditCard />} label="Plans" onClick={handlePlansClick} />
          {(user.data.team_role === "owner" || user.data.team_role === "staff") && (
           <SidebarLink icon={<Settings />} label="Admin" onClick={handleAdminClick} />
          )}
        </nav>
        <div className="mt-auto">
          <div className="flex items-center justify-between p-3" style={{
            background: "rgb(20,20,20)",
            borderRadius: "40px"
          }}>
            <div className="flex items-center">
              <img
                src={getAvatarUrl(user)}
                alt="User"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <h3 className="text-sm font-semibold">{user.discord.globalName || user.discord.username}</h3>
              </div>
            </div>
            <div className="relative">
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-300 hover:text-white focus:outline-none"
              >
                <MoreHorizontal size={35} style={{
                  marginTop: "4px"
                }} />
              </button>
              {isMenuOpen && (
                <div className="absolute right-0 bottom-full mb-2 w-48 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Home</a>
                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Logout</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 p-8 overflow-auto">
        <div className="mb-6">
          <h2 className="text-2xl font-bold mb-4" style={{
            marginTop: "25px"
          }}>Configurações de links sociais</h2>
          <h3 className="text-xl mb-4">Lista de links</h3>
          {Object.keys(links).map(platform => (
            <SocialLinkInput
              key={platform}
              user={user}
              platform={platform}
              value={links[platform]}
              onChange={handleLinkChange}
            />
          ))}
          <p className="text-sm text-gray-500 mt-2">
            Se quiser remover o link, insira "@" no campo correspondente.
          </p>
        </div>
        <button
        className="relative bg-[#181818] text-white text-lg font-normal py-3 px-8 overflow-hidden"
        style={{ borderRadius: '15px' }}
        onClick={handleSave}
      >
        <div className="absolute inset-0 bg-transparent opacity-50"></div>
        <div className="relative z-10">Save Changes</div>
      </button>

       <ToastContainer />
      </div>
    </div>
  );
};

const SidebarLink = ({ icon, label, onClick, active }) => (
  <div className={`flex items-center p-3 rounded-lg mb-2 ${active ? 'violet bg-opacity-50' : 'hover:bg-white hover:bg-opacity-30'} transition-all transform hover:scale-105`} onClick={onClick}>
    {React.cloneElement(icon, { size: 20, className: 'text-gray-300' })}
    <span className="ml-3 font-medium">{label}</span>
  </div>
);

const SocialLinkInput = ({ platform, value, onChange, user }) => {
  const socialIcons = {
    instagram: FaInstagram,
    twitter: FaTwitter,
    github: FaGithub,
    tiktok: FaTiktok,
    spotify: FaSpotify,
    twitch: FaTwitch,
    pinterest: FaPinterestP,
    linkedin: FaLinkedin,
    gitlab: FaGitlab,
    lastfm: FaLastfm,
    kick: FaKickstarterK
  };

  const Icon = socialIcons[platform] || FaLink;
  return (
    <div className="flex items-center mb-2 bg-[#181818] rounded-lg overflow-hidden">
      <div className="p-2">
        <Icon size={24} className="text-gray-400" />
      </div>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(platform, e.target.value)}
        placeholder={`@${(user.data.socialLinks && user.data.socialLinks[platform]) || platform}`}
        className="flex-grow bg-transparent text-white p-2 outline-none"
      />
    </div>
  );
};

export default Socials;
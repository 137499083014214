import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate} from 'react-router-dom';
import ParticlesPage from '../components/ParticlesPage'
import DefaultTheme from '../themes/DefaultTheme'
import Moana from '../themes/Moana'
import Cinnamoroll from '../themes/Cinnamoroll'
import HollowKnight from '../themes/HollowKnight'
import StrangerThings from '../themes/StrangerThings'
import HelloKitty from '../themes/HelloKitty'
import Kuromi from '../themes/Kuromi'
import Pucca from '../themes/Pucca'

import { Tilt } from 'react-tilt'
import { Tooltip } from 'react-tooltip'

const UserProfile = () => {
  const { username } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [iconLinks, setIconLinks] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`https://api-auth.victims.lol/page`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ url: username }),
        });

        const data = await response.json();

        if (data.found === false || response.status === 404) {
          setErrorMessage('Página não reivindicada. Você pode reivindicá-la!');
        } else {

          const link = document.createElement('link');
          link.rel = 'icon';
          link.type = 'image/png';
          link.href = data.owner.data.page_favicon ? `https://api-auth.victims.lol/api/proxy?url=${data.owner.data.page_favicon}` : `https://api.victims.lol/api/avatar/${data.owner.discord.id}`;
          const existingLink = document.querySelector("link[rel*='icon']");
          if (existingLink) {
            document.head.removeChild(existingLink);
          }
          document.head.appendChild(link);

          const background = data.owner.data.page_background;
          const pageTheme = data.owner.data.page_theme;
          const premium = data.owner.data.current_premium;
          const cursor = data.owner.data.page_curso;

          if (cursor && cursor !== null && cursor !== undefined && cursor !== "") {
            document.body.style.cursor = `url('https://api-auth.victims.lol/api/proxy?url=${cursor}'), auto`;
          } else {
            document.body.style.cursor = "default";
          }

          if (pageTheme && pageTheme.toLowerCase() === 'moana') {
            document.documentElement.style.height = '100%';
            document.body.style.margin = '0';
            document.body.style.padding = '0';
            document.body.style.height = '100%';
            document.body.style.backgroundImage = `url('https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/moana-1727111939182.png')`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';
        } else if (pageTheme && pageTheme.toLowerCase() === 'cinnamoroll') {
            document.documentElement.style.height = '100%';
            document.body.style.margin = '0';
            document.body.style.padding = '0';
            document.body.style.height = '100%';
            document.body.style.backgroundImage = `url('https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/cb301c8c909ffa407603b4cac909a03d.png')`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';
        } else if (pageTheme && pageTheme.toLowerCase() === 'hollow') {
            document.documentElement.style.height = '100%';
            document.body.style.margin = '0';
            document.body.style.padding = '0';
            document.body.style.height = '100%';
            document.body.style.backgroundImage = `url('https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/hollow-1727112769769-1730595369818.png')`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';
        } else if (pageTheme && pageTheme.toLowerCase() === 'strange') {
            document.documentElement.style.height = '100%';
            document.body.style.margin = '0';
            document.body.style.padding = '0';
            document.body.style.height = '100%';
            document.body.style.backgroundImage = `url('https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/8ff1051cca14d5c7a7f2f1e8c24fd55c-1722957956787-1730595404159.png')`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';
        } else if (pageTheme && pageTheme.toLowerCase() === 'hello') {
            document.documentElement.style.height = '100%';
            document.body.style.margin = '0';
            document.body.style.padding = '0';
            document.body.style.height = '100%';
            document.body.style.backgroundImage = `url('https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/3zb4Fme-1728239596852-1730595276988.png')`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';
        } else if (pageTheme && pageTheme.toLowerCase() === 'kuromi') {
            document.documentElement.style.height = '100%';
            document.body.style.margin = '0';
            document.body.style.padding = '0';
            document.body.style.height = '100%';
            document.body.style.backgroundImage = `url('https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/10726147-1730594144080.jpg')`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';
        } else if (pageTheme && pageTheme.toLowerCase() === 'pucca') {
            document.documentElement.style.height = '100%';
            document.body.style.margin = '0';
            document.body.style.padding = '0';
            document.body.style.height = '100%';
            document.body.style.backgroundImage = `url('https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/2672466-1728239876355-1730595644524.jpg')`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';
        } else if ((premium === "gold" || premium === "diamond") && background !== null && background !== undefined && background !== "") {
            document.documentElement.style.height = '100%';
            document.body.style.margin = '0';
            document.body.style.padding = '0';
            document.body.style.height = '100%';
            document.body.style.backgroundImage = `url('https://api-auth.victims.lol/api/proxy?url=${background}')`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';
        } else {
            document.body.style.background = 'linear-gradient(180deg, #010113 0%, #200e31 100%)';
            document.body.style.minHeight = '100vh';
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.justifyContent = 'center';
            document.body.style.alignItems = 'center';
          }

          setProfileData(data);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('Ocorreu um erro ao carregar a página.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [username]);

  useEffect(() => {
    if (profileData) {
      const apiResponse = profileData.owner.data.socialLinks || {};
      const filteredLinks = filterLinks(apiResponse);
      setIconLinks(filteredLinks);

    }
  }, [profileData]);

  const filterLinks = (apiResponse) => {
    const baseUrls = {
      github: 'https://github.com/',
      gitlab: 'https://gitlab.com/',
      instagram: 'https://instagram.com/',
      kick: 'https://kick.com/',
      lastfm: 'https://www.last.fm/user/',
      linkedin: 'https://linkedin.com/in/',
      pinterest: 'https://pinterest.com/',
      spotify: 'https://open.spotify.com/user/',
      tiktok: 'https://www.tiktok.com/@',
      twitch: 'https://twitch.tv/',
      twitter: 'https://twitter.com/',
    };

    return Object.entries(apiResponse)
      .filter(([key, value]) => value)
      .map(([key, value]) => ({
        type: key,
        link: baseUrls[key] + value,
      }));
  };

  if (loading) return (
    <div className="flex items-center justify-center h-screen bg-black">
      <section className="relative w-36 h-20 flex justify-center items-center">
        <div className="absolute w-[90px] h-[51px] rotate-0">
          <div className="w-full h-full relative">
            <span className="one h1 absolute left-0 w-1 bg-white" />
            <span className="two h4 absolute right-0 w-1 bg-white" />
          </div>
        </div>

        <div className="absolute w-[90px] h-[51px] rotate-[60deg]">
          <div className="w-full h-full relative">
            <span className="one h5 absolute left-0 w-1 bg-white" />
            <span className="two h2 absolute right-0 w-1 bg-white" />
          </div>
        </div>

        <div className="absolute w-[90px] h-[51px] -rotate-[60deg]">
          <div className="w-full h-full relative">
            <span className="one h6 absolute left-0 w-1 bg-white" />
            <span className="two h3 absolute right-0 w-1 bg-white" />
          </div>
        </div>
      </section>

      <style>
        {`
          .h1 { animation: load1 7.2s ease infinite; }
          .h2 { animation: load2 7.2s ease 0.6s infinite; }
          .h3 { animation: load3 7.2s ease 1.2s infinite; }
          .h4 { animation: load4 7.2s ease 1.8s infinite; }
          .h5 { animation: load5 7.2s ease 2.4s infinite; }
          .h6 { animation: load6 7.2s ease 3s infinite; }

          @keyframes load1 {
            0% { bottom: 0; height: 0; }
            6.944% { bottom: 0; height: 100%; }
            50% { top: 0; height: 100%; }
            59.944% { top: 0; height: 0; }
          }

          @keyframes load2 {
            0% { top: 0; height: 0; }
            6.944% { top: 0; height: 100%; }
            50% { bottom: 0; height: 100%; }
            59.944% { bottom: 0; height: 0; }
          }

          @keyframes load3 {
            0% { top: 0; height: 0; }
            6.944% { top: 0; height: 100%; }
            50% { bottom: 0; height: 100%; }
            59.944% { bottom: 0; height: 0; }
          }

          @keyframes load4 {
            0% { top: 0; height: 0; }
            6.944% { top: 0; height: 100%; }
            50% { bottom: 0; height: 100%; }
            59.944% { bottom: 0; height: 0; }
          }

          @keyframes load5 {
            0% { bottom: 0; height: 0; }
            6.944% { bottom: 0; height: 100%; }
            50% { top: 0; height: 100%; }
            59.944% { top: 0; height: 0; }
          }

          @keyframes load6 {
            0% { bottom: 0; height: 0; }
            6.944% { bottom: 0; height: 100%; }
            50% { top: 0; height: 100%; }
            59.944% { top: 0; height: 0; }
          }
        `}
      </style>
    </div>
  );

  if (errorMessage === "Página não reivindicada. Você pode reivindicá-la!") {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black text-white">
        <div
          style={{
            backgroundColor: '#101010',
            border: '2px solid #161616',
            borderRadius: '15px',
            boxSizing: 'border-box',
            maxWidth: '500px',
            padding: '25px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <div className="text-6xl mb-4">!</div>
          <h1 className="text-2xl font-bold mb-4">This page url is not claimed</h1>
          <p className="mb-6">Claim this name quickly!</p>
          <div className="flex space-x-4 justify-center">
            <button
              onClick={() => navigate('/')}
              style={{
                alignItems: "center",
                backgroundColor: "#151515",
                border: "2px solid #191919",
                borderRadius: "12px",
                color: "#fafafa",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                padding: "7px 15px",
                textDecoration: "none",
                transition: ".2s",
              }}
            >
              Go Home
            </button>
            <button
              onClick={() => navigate('/dashboard/customize')}
              style={{
                alignItems: "center",
                backgroundColor: "#151515",
                border: "2px solid #191919",
                borderRadius: "12px",
                color: "#fafafa",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                padding: "7px 15px",
                textDecoration: "none",
                transition: ".2s",
              }}
            >
              Claim Now!
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black">
        <img
          src="https://cdn.discordapp.com/attachments/1259905963721359360/1278740656625418240/500.png?ex=6706a38a&is=6705520a&hm=b9bcfec3200820671012b7a1ae0c80ad77dd225be6e5bebceb8cb3d8ff34627a&"
          alt="Página não encontrada"
          className="w-full max-w-md"
        />
        <p className="text-white text-lg mt-6">Erro ao carregar a página.</p>
      </div>
    );
  }

  const users = [
    { role: 'Owner', data: profileData.owner },
  ];

  let limit;
  if (profileData.owner.data.current_premium === 'gold') {
    limit = 5;
  } else if (profileData.owner.data.current_premium === 'diamond') {
    limit = 7;
  } else {
    limit = 2;
  }

  for (let i = 1; i <= limit; i++) {
    users.push({ role: `User ${i}`, data: profileData[`user${i}`] });
  }

  return (
    <div className="flex flex-col items-center">
    <Helmet>
      <title>{`@${profileData.owner.discord.globalName || profileData.owner.discord.username} | victims.lol`}</title>
      <meta name="theme-color" content="#FFFFFF" />
      <meta
        name="description"
        content={profileData.owner.data.page_description || `This is ${profileData.owner.discord.globalName || profileData.owner.discord.username}'s profile on victims.lol`}
      />
      <meta
        name="author"
        content={`@${profileData.owner.discord.globalName || profileData.owner.discord.username}`}
      />

      <meta
        property="og:image"
        content={`https://api.victims.lol/api/card/image?username=${profileData.owner.discord.globalName || profileData.owner.discord.username}&urlPage=${profileData.owner.data.page_url}&idDiscord=${profileData.owner.discord.id}`}
      />
      <meta
        property="og:title"
        content={`@${profileData.owner.discord.globalName || profileData.owner.discord.username}`}
      />
      <meta
        property="og:description"
        content={profileData.owner.data.page_description || `This is ${profileData.owner.discord.globalName || profileData.owner.discord.username}'s profile on victims.lol`}
      />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={`@${profileData.owner.discord.globalName || profileData.owner.discord.username}`}
      />
      <meta
        name="twitter:description"
        content={profileData.owner.data.page_description || `This is ${profileData.owner.discord.globalName || profileData.owner.discord.username}'s profile on victims.lol`}
      />
      <meta
        name="twitter:image"
        content={`https://api.victims.lol/api/card/image?username=${profileData.owner.discord.globalName || profileData.owner.discord.username}&urlPage=${profileData.owner.data.page_url}&idDiscord=${profileData.owner.discord.id}`}
      />
    </Helmet>

      {profileData && (
       (
       (profileData.owner.data.partials?.trim() !== "" && profileData.owner.data.partials?.trim() !== "none") ||
       (profileData.owner.data.page_theme?.trim() !== "" && profileData.owner.data.page_theme?.trim() !== "none"  && profileData.owner.data.page_theme?.trim() !== "default"  && profileData.owner.data.page_theme?.trim() !== "strange")
       ) && (
        <ParticlesPage data={profileData.owner.data} />
       )
      )}

      {(profileData && (profileData.owner.data.page_theme === "default" || profileData.owner.data.page_theme === "")) && (
         <DefaultTheme
          profileData={profileData}
          iconLinks={iconLinks}
          users={users}
          Tilt={Tilt}
          Tooltip={Tooltip}
         />
      )}

      {(profileData && (profileData.owner.data.page_theme === "moana")) && (
         <Moana
          profileData={profileData}
          iconLinks={iconLinks}
          users={users}
          Tilt={Tilt}
          Tooltip={Tooltip}
         />
      )}

      {(profileData && (profileData.owner.data.page_theme === "cinnamoroll")) && (
         <Cinnamoroll
          profileData={profileData}
          iconLinks={iconLinks}
          users={users}
          Tilt={Tilt}
          Tooltip={Tooltip}
         />
      )}

      {(profileData && (profileData.owner.data.page_theme === "hollow")) && (
         <HollowKnight
          profileData={profileData}
          iconLinks={iconLinks}
          users={users}
          Tilt={Tilt}
          Tooltip={Tooltip}
         />
      )}

      {(profileData && (profileData.owner.data.page_theme === "strange")) && (
         <StrangerThings
          profileData={profileData}
          iconLinks={iconLinks}
          users={users}
          Tilt={Tilt}
          Tooltip={Tooltip}
         />
      )}

      {(profileData && (profileData.owner.data.page_theme === "hello")) && (
         <HelloKitty
          profileData={profileData}
          iconLinks={iconLinks}
          users={users}
          Tilt={Tilt}
          Tooltip={Tooltip}
         />
      )}

      {(profileData && (profileData.owner.data.page_theme === "kuromi")) && (
         <Kuromi
          profileData={profileData}
          iconLinks={iconLinks}
          users={users}
          Tilt={Tilt}
          Tooltip={Tooltip}
         />
      )}

      {(profileData && (profileData.owner.data.page_theme === "pucca")) && (
         <Pucca
          profileData={profileData}
          iconLinks={iconLinks}
          users={users}
          Tilt={Tilt}
          Tooltip={Tooltip}
         />
      )}

     </div>
  );
};

export default UserProfile;

import React from 'react';
import { Eye, Users, FileUp, Diamond } from 'lucide-react';

const StatsSection = () => {
  const stats = [
    { icon: <Eye size={24} />, value: '45000+', label: 'Profile Views', percentage: '3% than last month' },
    { icon: <Users size={24} />, value: '2100+', label: 'Active Users', percentage: '5% than last month' },
    { icon: <FileUp size={24} />, value: '18000+', label: 'Files Uploaded', percentage: '2% than last month' },
    { icon: <Diamond size={24} />, value: '100+', label: 'Subscribers', percentage: '1% than last month' },
  ];

  return (
    <div className="bg-black text-white py-16 px-4 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-black via-[#542b55] to-black opacity-50 z-0"></div>
      <div className="relative z-10 max-w-6xl mx-auto">
        <h1 className="text-5xl font-bold mb-6 text-center leading-tight">
          Join over <span className="text-[#542b55] animate-pulse">2100+</span> people on victims.lol
        </h1>
        <p className="text-xl text-gray-300 mb-12 text-center max-w-2xl mx-auto">
          Create feature-rich, customizable, and modern bio pages, along with fast and secure file hosting, all with victims.lol.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-black/40 backdrop-blur-lg border border-white/10 rounded-2xl p-6 shadow-2xl transform transition-all duration-300 hover:scale-105 hover:shadow-[#542b55]/50"
            >
              <div className="flex items-center mb-4">
                <div className="bg-gradient-to-br from-[#000000] to-[#542b55] rounded-xl p-3 mr-4 shadow-md">
                  {React.cloneElement(stat.icon, { className: 'text-white' })}
                </div>
                <div>
                  <h2 className="text-3xl font-extrabold text-white">{stat.value}</h2>
                  <p className="text-gray-400">{stat.label}</p>
                </div>
              </div>
              <p className="text-green-500 font-semibold text-sm bg-green-500/10 rounded-full px-3 py-1 inline-block">
                {stat.percentage}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsSection;

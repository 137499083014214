// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 20px;
    background-color: transparent;
    max-width: 1200px;
    margin: 0 auto;
    color: #DDDDDD;
}

.footer-section {
    flex: 1 1;
    min-width: 200px;
    padding: 0 20px;
    box-sizing: border-box;
}

.footer-section h4 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #FFFFFF;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #DDDDDD;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #FFFFFF;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    width: 100%;
}

@media (max-width: 1200px) {
    .footer-section {
        flex: 1 1 300px;
    }
}

@media (max-width: 768px) {
    footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        flex: 1 1 100%;
        text-align: center;
    }

    .footer-bottom {
        margin-top: 40px;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,kBAAkB;IAClB,6BAA6B;IAC7B,iBAAiB;IACjB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,cAAc;QACd,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["footer {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: space-between;\r\n    padding: 40px 20px;\r\n    background-color: transparent;\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n    color: #DDDDDD;\r\n}\r\n\r\n.footer-section {\r\n    flex: 1;\r\n    min-width: 200px;\r\n    padding: 0 20px;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.footer-section h4 {\r\n    font-size: 18px;\r\n    margin-bottom: 10px;\r\n    color: #FFFFFF;\r\n}\r\n\r\n.footer-section ul {\r\n    list-style: none;\r\n    padding: 0;\r\n}\r\n\r\n.footer-section ul li {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.footer-section ul li a {\r\n    color: #DDDDDD;\r\n    text-decoration: none;\r\n    transition: color 0.3s ease;\r\n}\r\n\r\n.footer-section ul li a:hover {\r\n    color: #FFFFFF;\r\n}\r\n\r\n.footer-bottom {\r\n    text-align: center;\r\n    margin-top: 20px;\r\n    font-size: 14px;\r\n    width: 100%;\r\n}\r\n\r\n@media (max-width: 1200px) {\r\n    .footer-section {\r\n        flex: 1 1 300px;\r\n    }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    footer {\r\n        flex-direction: column;\r\n        align-items: center;\r\n    }\r\n\r\n    .footer-section {\r\n        flex: 1 1 100%;\r\n        text-align: center;\r\n    }\r\n\r\n    .footer-bottom {\r\n        margin-top: 40px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.community-card {
    background: linear-gradient(135deg, #000, #333);
    color: #fff;
    padding: 40px;
    text-align: center;
    border-radius: 10px;
    margin: 40px auto;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.community-card h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.community-card p {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.community-card button {
    background-color: #000;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.community-card button:hover {
    background-color: #fff;
    color: #000;
}

.community-card:hover {
    transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/css/JoinNow.css"],"names":[],"mappings":"AAAA;IACI,+CAA+C;IAC/C,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,wCAAwC;IACxC,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,6CAA6C;AACjD;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".community-card {\r\n    background: linear-gradient(135deg, #000, #333);\r\n    color: #fff;\r\n    padding: 40px;\r\n    text-align: center;\r\n    border-radius: 10px;\r\n    margin: 40px auto;\r\n    max-width: 800px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    transition: transform 0.3s;\r\n}\r\n\r\n.community-card h2 {\r\n    font-size: 2em;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.community-card p {\r\n    font-size: 1.2em;\r\n    margin-bottom: 30px;\r\n}\r\n\r\n.community-card button {\r\n    background-color: #000;\r\n    color: #fff;\r\n    border: 2px solid #fff;\r\n    border-radius: 50px;\r\n    padding: 10px 20px;\r\n    font-size: 1em;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s, color 0.3s;\r\n}\r\n\r\n.community-card button:hover {\r\n    background-color: #fff;\r\n    color: #000;\r\n}\r\n\r\n.community-card:hover {\r\n    transform: scale(1.05);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

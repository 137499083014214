import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const Profile = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!loading) {
      if (user && user.data.page_url !== "") {
        const pageUrl = user.data.page_url;
        navigate(`/${pageUrl}`);
      } else {
        navigate('/');
      }
    }
  }, [user, loading, navigate]);

  return null; 
};

export default Profile;

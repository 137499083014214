import React, { useState } from "react";
import { Search } from "lucide-react";
import { useNavigate } from 'react-router-dom';

function UserList({ adminData }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("all");

  const navigate = useNavigate();

  const filteredUsers = (Array.isArray(adminData) ? adminData : []).filter((user) => {
    const searchLower = searchTerm.toLowerCase();

    // Filter by type
    if (filterType === "page_url" && !user.page_url) {
      return false;
    }

    // Search logic
    const id = user.discordUser?.id || "";
    const username = user.discordUser?.username || "";
    const globalName = user.discordUser?.globalName || "";
    const pageUrl = user.page_url || "";

    return (
      id.toLowerCase().includes(searchLower) ||
      username.toLowerCase().includes(searchLower) ||
      globalName.toLowerCase().includes(searchLower) ||
      pageUrl.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="bg-black p-6 rounded-2xl shadow-lg" style={{ maxWidth: '2000px', height: 'auto', margin: '50px auto' }}>
      <h1 className="text-4xl font-bold text-white font-sans text-center mb-8">Users</h1>

      <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mb-6">
        <div className="relative w-full max-w-md">
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-10 py-2 rounded-lg text-gray-900 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 transition-all duration-300 pl-10"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>

        <div className="relative w-full max-w-md">
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="w-full px-4 py-2 rounded-lg text-gray-900 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 transition-all duration-300"
          >
            <option value="all">All Users</option>
            <option value="page_url">Users with Page URL</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-items-center">
        {filteredUsers.map((user, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-[#232323] bg-opacity-70 p-5 rounded-xl shadow-lg w-full max-w-[250px] transform transition-all duration-300 hover:scale-110 hover:rotate-1 relative overflow-hidden"
            style={{
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.8)',
            }}
          >
            <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-purple-500 to-purple-800 opacity-0 hover:opacity-20 transition-opacity duration-300"></div>

            <div className="absolute top-3 right-3 cursor-pointer text-gray-400 hover:text-white transition-colors duration-300" onClick={() => navigate(`/dashboard/admin/${user.discordUser.id}/user`)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v2m4.243-1.757l1.414 1.414M20 12h-2m1.757 4.243l-1.414 1.414M12 20v-2m-4.243 1.757l-1.414-1.414M4 12h2m-1.757-4.243l1.414-1.414M9 12a3 3 0 116 0 3 3 0 01-6 0z" />
              </svg>
            </div>

            <div
              className="rounded-full p-1.5 mb-4 transition-transform duration-500 hover:scale-110"
              style={{
                background: 'linear-gradient(135deg, #542b55, #232323)',
              }}
            >
              <img
                src={user.discordUser.displayAvatarURL}
                alt={`${user.discordUser.username}'s avatar`}
                className="w-16 h-16 rounded-full"
              />
            </div>

            <div className="text-center">
              <h2 className="text-2xl font-semibold text-white">{user.discordUser.globalName || user.discordUser.username}</h2>
              <p className="text-sm text-gray-300">{user.discordUser.username}</p>
              {user.page_url && (
                <p className="text-xs text-gray-400 mt-2 truncate max-w-full">
                  URL: {user.page_url}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>

      {filteredUsers.length === 0 && (
        <div className="text-center text-gray-400 mt-10">
          No users found matching your search criteria.
        </div>
      )}
    </div>
  );
}

export default UserList;

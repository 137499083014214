import { useState } from "react";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How do I get started with victims.lol?",
      answer:
        "To get started, simply navigate to our homepage and sign up for an account. From there, you can explore our features and choose a plan that suits your needs.",
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept credit cards, PayPal, and cryptocurrency.",
    },
    {
      question: "Can I upgrade or downgrade my plan later?",
      answer:
        "Yes, you can upgrade or downgrade your plan at any time from your account settings.",
    },
    {
      question: "Is there a free trial available?",
      answer: "Yes, we offer a 7-day free trial for new users.",
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="py-20 bg-transparent">
      <h2 className="text-3xl text-white mb-10 text-center">Frequently Asked Questions</h2>
      <div className="max-w-2xl mx-auto text-white">
        {faqs.map((faq, index) => (
          <div key={index} className={`mb-5 border border-[#333333] rounded-lg overflow-hidden`}>
            <div
              onClick={() => toggleFAQ(index)}
              className={`bg-[#1111111] cursor-pointer px-4 py-4 flex justify-between items-center transition-all duration-300 ${openIndex === index ? 'rounded-b-none' : ''}`}
            >
              <span className="font-semibold text-lg">{faq.question}</span>
              <span className={`transition-transform duration-300 ${openIndex === index ? 'rotate-180' : ''}`}>
                <svg
                  className="w-5 h-5 text-white"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </div>
            <div
              className={`overflow-hidden transition-all duration-300 bg-[#111111] border-t border-[#333333] ${openIndex === index ? 'max-h-96 py-3 px-4' : 'max-h-0 py-0'}`}
            >
              <p className={`text-gray-300 ${openIndex === index ? 'block' : 'hidden'}`}>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transparent-hr {
  border: none;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.438); 
}

.premium {
  background: linear-gradient(269deg, #9849AC 3.08%, #3E1E46 128.69%);
  color: white;
  padding: 20px;
  border-radius: 11px;
  text-decoration: none;
  transition: background 0.3s;
}

.violet {
  background: rgba(218, 102, 218, .155);
}`, "",{"version":3,"sources":["webpack://./src/css/Customize.css"],"names":[],"mappings":"AAGA;EACE,YAAY;EACZ,WAAW;EACX,4CAA4C;AAC9C;;AAEA;EACE,mEAAmE;EACnE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');\r\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');\r\n\r\n.transparent-hr {\r\n  border: none;\r\n  height: 1px;\r\n  background-color: rgba(255, 255, 255, 0.438); \r\n}\r\n\r\n.premium {\r\n  background: linear-gradient(269deg, #9849AC 3.08%, #3E1E46 128.69%);\r\n  color: white;\r\n  padding: 20px;\r\n  border-radius: 11px;\r\n  text-decoration: none;\r\n  transition: background 0.3s;\r\n}\r\n\r\n.violet {\r\n  background: rgba(218, 102, 218, .155);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

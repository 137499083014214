import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const UserProfile = () => {
  const { username } = useParams(); // Pega o parâmetro da URL

  useEffect(() => {
    // Alterar as meta tags dinamicamente com base no parâmetro da URL
    document.title = `Perfil de ${username} - Victims`;

    const metaDescription = document.querySelector('meta[name="description"]');
    const metaImage = document.querySelector('meta[property="og:image"]');
    const metaUrl = document.querySelector('meta[property="og:url"]');

    if (metaDescription) metaDescription.setAttribute('content', `Veja o perfil de ${username} na Victims.`);
    if (metaImage) metaImage.setAttribute('content', `https://api.victims.lol/api/card/image?username=${username}&urlPage=teste&idDiscord=722154600316141761`);
    if (metaUrl) metaUrl.setAttribute('content', `https://victims.lol/${username}`);
  }, [username]);

  return (
    <div>
      <h1>Bem-vindo ao perfil de {username}!</h1>
      {/* Outros componentes e conteúdo do perfil */}
    </div>
  );
};

export default UserProfile;

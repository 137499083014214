import React from 'react';
import { FaShieldAlt, FaWallet, FaRocket } from 'react-icons/fa';

const FeaturesSection = () => {
  const features = [
    {
      icon: <FaShieldAlt />,
      title: 'Encrypted Security',
      description: 'We have a well-encrypted and safe security system for the users.'
    },
    {
      icon: <FaWallet />,
      title: 'Easy & Fast Transaction',
      description: 'We have a flow of purchase transactions that are easy and fast.'
    },
    {
      icon: <FaRocket />,
      title: 'Quick Delivery',
      description: 'Our transactions are quick and efficient, ensuring a smooth experience.'
    }
  ];

  return (
    <div className="relative bg-gradient-to-br from-black via-[#542b55] to-black py-16 mt-10">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-white mb-4">Your Amazing Page</h2>
        <p className="text-gray-300 max-w-2xl mx-auto">
          We provide an encrypted and secure system, fast transactions, and efficient delivery.
        </p>
      </div>

      <div className="container mx-auto px-4 grid md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-black/40 backdrop-blur-lg border border-white/10 rounded-2xl p-6 shadow-2xl transform transition-all duration-300 hover:scale-105 hover:shadow-[#542b55]/50"
          >
            <div className="flex items-center mb-4">
              {React.cloneElement(feature.icon, { className: 'text-3xl text-white mr-4', title: feature.title })}
              <h3 className="text-xl font-semibold text-white">{feature.title}</h3>
            </div>
            <p className="text-gray-300">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;

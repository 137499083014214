import React, { useState, useEffect, useCallback } from 'react';
import { Home, Users, Star, CreditCard, Pencil, Settings, MoreHorizontal, SwatchBook } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/Dashboard.css';
import axios from 'axios'

const UsersPage = () => {
  const { user, loading, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [userCards, setUserCards] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  const handleCustomizeClick = () => {
    navigate('/dashboard/customize');
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleSocialsClick = () => {
    navigate('/dashboard/socials');
  };

  const handleThemeClick = () => {
    navigate('/dashboard/themes')
  };

  const handlePlansClick = () => {
    navigate('/pricing');
  };

  const handleAdminClick = () => {
    navigate('/dashboard/admin');
  };

  const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";

    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar
          ? `https://api.victims.lol/api/avatar/${user.discord.id}`
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar
        ? `https://api.victims.lol/api/avatar/${user.discord.id}`
        : defaultAvatar;
    }
};


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    navigate('/logout')
    fetchUser()
  };

  useEffect(() => {
    document.title = "victims.lol | users";

    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = 'https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/697d4ffd-9ea4-4a68-abad-ebdba5ae0ebb-1728241874427.png';

    const existingLink = document.querySelector("link[rel*='icon']");
    if (existingLink) {
      document.head.removeChild(existingLink);
    }

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const handleRemoveUser = async (userId) => {
    try {
      await axios.post(`https://api-auth.victims.lol/remove/user`, { user: user, id: userId });
      toast.success('User removed successfully');
      fetchUser();
      fetchUserData();
    } catch (error) {
      console.error(`Error removing user ${userId}:`, error);
      toast.error('Failed to remove user');
    }
  };

  const handleAddUser = async () => {
    try {
      const res = await axios.post('https://api-auth.victims.lol/save/user', { user, id: userId });

      if (res.status === 200) {
        toast.success('User saved successfully!');
        fetchUser();
        fetchUserData();
        setIsModalOpen(false);
        setUserId('');
        return;
      }

    } catch (error) {
      if (error.response) {
        if (error.response.status === 708) {
          toast.error('User limit reached.');
        } else if (error.response.status === 705) {
          toast.error('User is suspended.');
        } else if (error.response.status === 706) {
          toast.error('You cannot add yourself to the site.');
        } else if (error.response.status === 707) {
          toast.error('User already added.');
        } else {
          toast.error('Erro inesperado: ' + error.response.data.message);
        }
      } else {
        toast.error('Erro ao se conectar ao servidor.');
      }
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get('https://api-auth.victims.lol/api/me', { withCredentials: true });
      setUser(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserData = useCallback(async () => {
    const userIds = [user.data.user1, user.data.user2, user.data.user3, user.data.user4, user.data.user5, user.data.user6, user.data.user7]
      .filter(id => id && id !== "" && id !== null);

    const userDataPromises = userIds.map(id =>
      axios.get(`https://api-auth.victims.lol/api/users/${id}`)
        .then(response => response.data)
        .catch(error => {
          console.error(`Error fetching user ${id}:`, error);
          return null;
        })
    );

    const userData = await Promise.all(userDataPromises);
    setUserCards(userData.filter(data => data !== null));
    console.log(userData);
  }, [user]);

  useEffect(() => {
    if (!isLoading && user) {
      fetchUserData();
    }
  }, [isLoading, user, fetchUserData]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen text-white" style={{ background: "#000000" }}>
      <button
        className="block md:hidden fixed top-4 left-4 bg-gray-800 p-2 rounded"
        style={{ width: "40px" }}
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? 'X' : '☰'}
      </button>

      <div
        className={`w-64 bg-opacity-80 backdrop-filter backdrop-blur-lg p-4 flex flex-col overflow-auto fixed md:static transform transition-transform duration-300 z-30 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 h-full`}
        style={{ borderRadius: "0 20px 20px 0", background: "rgba(23, 23, 23, 0.8)" }}
      >
        <div className="mb-8 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={getAvatarUrl(user)}
              alt="User"
              className="w-12 h-12 rounded-full mr-3"
            />
            <div>
              <h2 className="text-sm font-bold text-gray-300" style={{ fontSize: '17px' }}>
                Welcome, {user.discord.globalName || user.discord.username}
              </h2>
              <p className="text-sm text-gray-400">
                {user.data.page_url ? `victims.lol/${user.data.page_url}` : 'Não definido.'}
              </p>
            </div>
          </div>
          <button className="text-gray-300 hover:text-gray-500 transition-all md:hidden" onClick={toggleSidebar}>
            X
          </button>
        </div>
        <hr className="mb-6 transparent-hr" />
        <nav className="flex-grow">
          <SidebarLink icon={<Home />} label="Dashboard" onClick={handleDashboardClick} />
          <SidebarLink icon={<Pencil />} label="Customize" onClick={handleCustomizeClick} />
          <SidebarLink icon={<Star />} label="Social Links" onClick={handleSocialsClick}  />
          <SidebarLink icon={<Users />} label="Users" active />
          <SidebarLink icon={<SwatchBook />} label="Themes" onClick={handleThemeClick} />
          <SidebarLink icon={<CreditCard />} label="Plans" onClick={handlePlansClick} />
          {(user.data.team_role === "owner" || user.data.team_role === "staff") && (
           <SidebarLink icon={<Settings />} label="Admin" onClick={handleAdminClick} />
          )}
        </nav>
        <div className="mt-auto">
          <div className="flex items-center justify-between p-3" style={{
            background: "rgb(20,20,20)",
            borderRadius: "40px"
          }}>
            <div className="flex items-center">
              <img
                src={getAvatarUrl(user)}
                alt="User"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <h3 className="text-sm font-semibold">{user.discord.globalName || user.discord.username}</h3>
              </div>
            </div>
            <div className="relative">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-300 hover:text-white focus:outline-none"
              >
                <MoreHorizontal size={35} style={{
                  marginTop: "4px"
                }} />
              </button>
              {isMenuOpen && (
                <div className="absolute right-0 bottom-full mb-2 w-48 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Home</a>
                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-600" role="menuitem">Logout</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 p-8 overflow-auto">
        <div className="mb-6">
          <h1 className="text-2xl font-bold mb-4">Users</h1>
          <div className="bg-[#181818] p-6 rounded-lg shadow-lg mb-6">
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-[#DA66DA40] hover:bg-white hover:bg-opacity-30 text-white font-bold py-2 px-4 rounded"
            >
              Add User
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {userCards.map((userData, index) => (
              <div
                key={index}
                className="bg-[#181818] p-6 rounded-lg shadow-xl transform transition-all duration-300 hover:scale-105 hover:shadow-2xl relative overflow-hidden"
              >
                <img
                  src={
                    userData.discord?.avatar
                      ? `https://cdn.discordapp.com/avatars/${userData.discord.id}/${userData.discord.avatar}.png`
                      : `https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png`
                  }
                  alt={userData.discord.username}
                  className="w-20 h-20 rounded-lg mx-auto mb-4 transition-all duration-300 transform hover:scale-110"
                />
                <h3 className="text-2xl font-semibold text-white mb-2 text-center">{userData.discord.globalName || userData.discord.username}</h3>
                <p className="text-gray-400 mb-1 text-center">ID: {userData.discord.id}</p>

                <button
                  className="absolute top-2 right-2 text-white bg-black bg-opacity-70 rounded-full p-1 transition-all duration-200 hover:bg-opacity-90 hover:scale-105"
                  onClick={() => handleRemoveUser(userData.discord.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>
        <ToastContainer />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#181818] p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Add User</h2>
            <input
              type="text"
              placeholder="Enter user ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              className="w-full p-2 mb-4 bg-[#232323] text-white rounded"
            />
            <div className="flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="mr-2 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                onClick={handleAddUser}
                className="px-4 py-2 bg-[#DA66DA40] hover:bg-white hover:bg-opacity-30 text-white rounded hover:bg-violet-700"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const SidebarLink = ({ icon, label, onClick, active }) => (
  <div className={`flex items-center p-3 rounded-lg mb-2 ${active ? 'violet bg-opacity-50' : 'hover:bg-white hover:bg-opacity-30'} transition-all transform hover:scale-105`} onClick={onClick}>
    {React.cloneElement(icon, { size: 20, className: 'text-gray-300' })}
    <span className="ml-3 font-medium">{label}</span>
  </div>
);

export default UsersPage;

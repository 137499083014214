import React from 'react'
import MusicInput from '../components/MusicInput';
import IconCard from '../components/IconCard';
import UserBio from '../components/UserBio'
import ViewsCounter from '../components/ViewsCounter'

const DefaultTheme = ({ profileData, iconLinks, users, Tilt, Tooltip }) => {

  function rgbToHex(value) {
    const rgb = value;
    if (typeof rgb !== 'number') {
      throw new Error('Entrada inválida. Esperado um número inteiro representando uma cor RGB.');
    }

    function componentToHex(c) {
      const hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }

    const hex = '#' + componentToHex((rgb >> 16) & 255) + componentToHex((rgb >> 8) & 255) + componentToHex(rgb & 255);
    return hex.toUpperCase();
  }

  function themeColorToRGBABanner(value) {
    const corR = (value >> 16) & 255;
    const corG = (value >> 8) & 255;
    const corB = value & 255;
    return `rgba(${corR}, ${corG}, ${corB}, 0.8)`;
  }

  function themeColorToRGBABorder(value) {
    const corR = (value >> 16) & 255;
    const corG = (value >> 8) & 255;
    const corB = value & 255;
    return `rgba(${corR}, ${corG}, ${corB}, 0.7)`;
  }

  function themeColorToRGBA(value) {
    const corR = (value >> 16) & 255;
    const corG = (value >> 8) & 255;
    const corB = value & 255;
    return `rgba(${corR}, ${corG}, ${corB}, 0.9)`;
  }

  const getAvatarUrl = (user) => {
    const isPremium = user && user.data && (user.data.current_premium === "gold" || user.data.current_premium === "diamond");
    const defaultAvatar = "https://api-auth.victims.lol/api/proxy?url=https://cdn.victims.lol/uploads/stock-vector-black-male-user-symbol-silver-plated-metallic-icon-1727093441091.png";
    
    if (isPremium) {
      if (user.data.user_avatar) {
        return `https://api-auth.victims.lol/api/proxy?url=${user.data.user_avatar}`;
      } else {
        return user.discord.avatar 
          ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
          : defaultAvatar;
      }
    } else {
      return user.discord.avatar 
        ? `https://api.victims.lol/api/avatar/${user.discord.id}` 
        : defaultAvatar;
    }
};

  const validUsers = users.filter(user => user.data !== null);

  return (
    <>
    {profileData && (
      <h1 className="text-7xl font-bold mt-5 " style={{
        color: "#ffffff"
      }}>{profileData.owner.data.page_name}</h1>
    )}

    {profileData && profileData.owner.data.profile_views === true && (
      <ViewsCounter
       views={profileData.owner.data.views}
      />
    )}
    
    {profileData && profileData.owner.data.page_button && profileData.owner.data.page_button.trim() !== "" && (
      <a 
       href={profileData.owner.data.page_button} 
       className="bg-[#d9d9d970] text-white font-bold rounded-full transition duration-300 ease-in-out mt-4" 
       style={{
        height: "45px",
        width: "210px",
        fontFamily: "Inter",
        fontSize: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none"
       }}
      >
       CLIQUE
      </a>
    )}

    {profileData && profileData.owner.data.page_song && profileData.owner.data.page_song.trim() !== "" && (
      <MusicInput
      link={profileData.owner.data.page_song}
      cover={profileData.owner.data.page_cover}
       />
    )}

    <div className="flex space-x-1 items-center justify-center mt-4">
    {iconLinks.map((icon) => (
      <IconCard key={icon.type} type={icon.type} link={icon.link} />
    ))}
    </div>

    <Tooltip 
     id="my-tooltip" 
     data-tooltip-offset={4}
     className="text-gray-300 rounded-md p-1 shadow-lg z-50" 
     style={{ 
      border: '1px solid rgba(255, 255, 255, 0.7)', 
      fontFamily: 'sans-serif', 
      fontWeight: 500,  
      fontSize: '14px',
      borderRadius: "7px"
     }} 
    >
    </Tooltip>

    <div
  className={`flex items-center justify-center h-screen grid gap-10 ${validUsers && validUsers.length >= 2 ? `grid-cols-1 sm:grid-cols-2 lg:grid-cols-${Math.min(validUsers.length, 4)}` : ''} mt-[65px] sm:mt-0`}
  style={{
    maxWidth: "1190px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px"
  }}
>
      {users.map(({  data }, index) => {
        if (!data) return null;

        const layout = profileData.owner.data.profile_layout;

        if (layout === "1" || !layout) {
          return (
            <Tilt className="Tilt" options={{ scale: 1.1, max: 15, transition: true, axis: null, reset: true, easing: "cubic-bezier(.03,.98,.52,.99)", perspective: 1000 }}>
            <div key={index} 
             className="rounded-[29px] shadow-lg p-5 w-[270px] h-[345px] relative text-black bg-opacity-80 backdrop-filter backdrop-blur-sm" 
             style={{
              background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 40%, ${
                data?.external?.user_profile?.theme_colors?.[1] 
                  ? rgbToHex(data.external.user_profile.theme_colors[1]) 
                  : 'rgba(0, 0, 0, 0.5)' 
              } 150%)`,
              }}
              >            
              <div className="flex items-center">
                <div className="rounded-full w-[112px] h-[112px] mr-4">
                  <img 
                    src={getAvatarUrl(data)}                    
                    alt={`${data.discord.username || 'Unknown User'}'s avatar`}
                    className="w-[112px] h-[112px] rounded-full object-cover"
                  />
                </div>
                <div className="grid grid-cols-3 gap-1 mt-4">
                  {data.external.badges && data.external.badges.length > 0 && data.external.badges.map((emoji, index) => (
                    <span key={index} className="text-xl relative" data-tooltip-id="my-tooltip" data-tooltip-content={emoji.description} data-tooltip-offset={5}
                    >
                      <img
                        src={emoji && emoji.icon ? `https://cdn.discordapp.com/badge-icons/${emoji.icon}.png` : emoji.image}
                        alt={`Badge ${emoji.icon}`}
                        className="inline-block w-6 h-6"
                      />
                    </span>
                  ))}
                </div>
              </div>
              <div className="bg-[#1f1f1f9f] rounded-[20px] p-4 mt-4" style={{
                maxHeight: "190px"
              }}>
                <h3 className="font-inter font-extrabold text-xl text-white">{data.discord.globalName || data.discord.username }</h3>
                <p className="font-inter font-extrabold text-lg text-white opacity-60">{data.discord.username}</p>
                <div className="border-t border-b border-gray-300 my-2"></div>
                <UserBio
                  bio={data.external.user.bio}
                />
              </div>
            </div>
            </Tilt>
          );
        }

        if (layout === "2") {
          return (
            <Tilt className="Tilt" options={{ scale: 1.1, max: 15, transition: true, axis: null, reset: true, easing: "cubic-bezier(.03,.98,.52,.99)", perspective: 1000 }}>
            <div
              key={index}
              className="rounded-lg shadow-md w-[250px] p-4 flex flex-col items-center bg-black bg-opacity-80 backdrop-filter backdrop-blur-sm"
              style={{
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 40%, ${
                  data?.external?.user_profile?.theme_colors?.[1]
                    ? rgbToHex(data.external.user_profile.theme_colors[1])
                    : 'rgba(0, 0, 0, 0.5)'
                } 150%)`,
              }}
            >
              <div className="w-[100px] h-[100px] rounded-full overflow-hidden mb-4 border-gray-700 transition-transform duration-200 transform hover:scale-110">
               <img
                src={getAvatarUrl(data)}
                alt={`${data.discord.username || 'Unknown User'}'s avatar`}
                className="w-full h-full object-cover"
               />
              </div>
              <div className="flex flex-col items-center text-center mb-4">
                <h3 className="font-inter font-extrabold text-xl text-white mb-1">
                  {data.discord.globalName || data.discord.username}
                </h3>
                <p className="font-inter text-lg text-white opacity-60">
                  @{data.discord.username}
                </p>
                <div className="flex space-x-1">
                {data.external.badges &&
                  data.external.badges.length > 0 &&
                  data.external.badges.map((emoji, index) => (
                    <span
                      key={index}
                      className="relative"
                      data-tooltip-id="my-tooltip" 
                      data-tooltip-content={emoji.description} 
                      data-tooltip-offset={5}
                    >
                      <img
                        src={emoji && emoji.icon ? `https://cdn.discordapp.com/badge-icons/${emoji.icon}.png` : emoji.image}
                        alt={`Badge ${emoji.icon}`}
                        className="inline-block w-6 h-6 transition-transform duration-200 hover:scale-110" 
                      />
                    </span>
                  ))}
               </div>
              </div>
      
            </div>
        </Tilt>
          );
        }

        if (layout === "3") {
          return (
            <Tilt className="Tilt" options={{ scale: 1.1, max: 15, transition: true, axis: null, reset: true, easing: "cubic-bezier(.03,.98,.52,.99)", perspective: 1000 }}>
              <div
                className="rounded-xl bg-opacity-80"
                style={{
                  height: "350px",
                  width: "290px",
                  background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 40%, ${
                    data?.external?.user_profile?.theme_colors?.[1] 
                      ? themeColorToRGBA(data.external.user_profile.theme_colors[1]) 
                      : 'rgba(0, 0, 0, 0.5)' 
                  } 150%)`,
                  border: `${
                    data?.external?.user_profile?.theme_colors?.[0] 
                      ? themeColorToRGBABorder(data.external.user_profile.theme_colors[0]) 
                      : '#000000'} solid 2px`,
                }}
              >
                <div
                  style={{ height: "120px", background: `${
                    data?.external?.user_profile?.theme_colors?.[0] 
                      ? themeColorToRGBABanner(data.external.user_profile.theme_colors[0]) 
                      : 'rgba(0, 0, 0, 0.5)' 
                  }`,
                  borderRadius: "10px 10px 0 0"
                 }} 
                ></div>
                <div className="px-5 pb-5 flex-grow">
                  <div className="relative">
                    <div className="bg-gray-400 rounded-full absolute -top-14 left-0 w-[90px] h-[90px] overflow-hidden mb-5 transition-transform duration-200 transform hover:scale-110" style={{
                      border: `${
                        data?.external?.user_profile?.theme_colors?.[0] 
                          ? themeColorToRGBABorder(data.external.user_profile.theme_colors[0]) 
                          : '#000000'} solid 2px`
                    }}>
                      <img
                        src={getAvatarUrl(data)}
                        alt={`${data.discord.username || "Unknown User"}'s avatar`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div
                      className="h-8 w-[165px] bg-opacity-80 rounded-full px-[6px] py-[1.4px] space-x-[2px] mt-3"
                      style={{ 
                        marginLeft: "90px", 
                        background: `${
                          data?.external?.user_profile?.theme_colors?.[0] 
                            ? themeColorToRGBABanner(data.external.user_profile.theme_colors[0], 0.4) 
                            : 'rgba(0, 0, 0, 0.4)'}` 
                       }} 
                    >
                      {data.external.badges &&
                        data.external.badges.length > 0 &&
                        data.external.badges.map((emoji, index) => (
                          <span
                            key={index}
                            className="relative"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={emoji.description}
                            data-tooltip-offset={5}
                          >
                            <img
                              src={
                                emoji && emoji.icon
                                  ? `https://cdn.discordapp.com/badge-icons/${emoji.icon}.png`
                                  : emoji.image
                              }
                              alt={`Badge ${emoji.icon}`}
                              className="inline-block w-[20px] h-[20px] -top-8 transition-transform duration-200 hover:scale-110" 
                            />
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="bg-opacity-40 rounded-xl p-3 mt-4" style={{
    maxHeight: "160px",
    background: `${data?.external?.user_profile?.theme_colors?.[0] 
      ? themeColorToRGBABanner(data.external.user_profile.theme_colors[0], 0.4) 
      : 'rgba(0, 0, 0, 0.4)'}` 
  }}>
  <h2 className="text-white text-lg font-bold">
    {data.discord.globalName || data.discord.username}
  </h2>
  
  <div className="flex items-center"> 
    <p className="text-[#ffffff] text-opacity-40 font-bold text-sm mr-2">@{data.discord.username}</p>
    
    {data.external.user.clan && (
      <div className="p-[2.5px] border border-gray-600 rounded-md bg-gray-700 flex items-center max-w-fit bg-opacity-10">
        <img 
          src={`https://cdn.discordapp.com/clan-badges/${data.external.user.clan?.identity_guild_id}/${data.external.user.clan?.badge}.png`} 
          alt="" 
          className="mr-1"
          style={{
            width: "13px",
            height: "13px"
          }}
        />
        <p className="text-white text-xs font-bold" style={{ marginRight: "3px" }}>{data.external.user.clan?.tag}</p>
      </div>
    )}
  </div>

  <div className="my-2 border-t border-gray-500"></div>
  <div className="mt-2">
    <h3 className="text-white text-xs font-bold">ABOUT ME</h3>
    <p className="text-gray-300 text-sm mt-1">
      <UserBio bio={data.external.user.bio} />
    </p>
  </div>
</div>

                </div>
              </div>
            </Tilt>
          );
        }        
        
        return null;
      })}
    </div>
    </>
  )
}

export default DefaultTheme;
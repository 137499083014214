import React from 'react';

function removeDiscordEmojis(text) {
  return text ? text.replace(/<:[\w_]+:\d+>|<a:[\w_]+:\d+>/g, '') : '';
}

function removeFormatting(text) {
  return text ? text.replace(/[*~]/g, '') : '';
}

const UserBio = ({ bio }) => {
  const renderBio = (bio) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    let cleanedBio = removeFormatting(removeDiscordEmojis(bio));

    const parts = cleanedBio.split('\n');

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part.split(urlRegex).map((subPart, subIndex) => {
          if (urlRegex.test(subPart)) {
            return (
              <a
                key={`link-${subIndex}`}
                href={subPart}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#6495ED' }}
              >
                {subPart}
              </a>
            );
          }
          return <span key={`text-${subIndex}`}>{subPart}</span>;
        })}
        {index !== parts.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <p
      className="text-white"
      style={{
        whiteSpace: 'nowrap', 
        overflow: 'hidden',
      }}
    >
      {renderBio(bio || 'No bio available')}
    </p>
  );
};

export default UserBio;
